import React from 'react';
import styled from 'styled-components';

import ItemLinkList from '@/components/__old__/AboutCleaning/components/ItemLinkList';

export type Props = {
  closeModal: () => void;
  servicesExtendedLinks: Array<{ label: string; link: string }>;
};

function LinksBlock({ closeModal, servicesExtendedLinks }: Props) {
  return (
    <Wrapper>
      <ItemLinkList
        servicesExtendedLinks={servicesExtendedLinks}
        closeModal={closeModal}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 15px 0;
`;

export default LinksBlock;
