import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import useCurrentPage from '@/hooks/useCurrentPage';
import { media } from '@/utils/mixin';
import TabContentCard from '@/modules/Templates/Subscription/components/Packages/Card';
import { PackagesCostTabsTypes } from '@/modules/Templates/Subscription/components/Packages/Packages.types';
import { OrderButton } from '@/modules/Templates/Subscription/components/OrderButton';

import { PackageCard } from './Card/TabContentCard.types';

function Packages() {
  const page = useCurrentPage();
  const templateFields = page.templateFields;

  const [activeIndexTab, setActiveIndexTab] = useState(0);

  if (!templateFields) return null;

  return (
    <Component>
      <ContentContainer>
        <Title>{templateFields.packageCostTitle}</Title>

        <TabBlock>
          <Cards>
            {templateFields.packageCostTabs[activeIndexTab].items.map(
              ({ title, price }: PackageCard, index: number) => (
                <TabContentCard key={index} title={title} price={price} />
              )
            )}
          </Cards>

          <Options>
            {templateFields.packageCostTabs[activeIndexTab].included}
          </Options>
        </TabBlock>

        <OrderButton />
      </ContentContainer>
    </Component>
  );
}

export default Packages;

const Component = styled.section`
  padding: 104px 0;
  background-color: ${colors.grayBg};

  ${media.tablet(css`
    padding: 40px 0;
  `)}
`;

const Title = styled.span`
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 32px;
  line-height: 24px;
  font-weight: 900;

  ${media.tablet(css`
    font-size: 28px;
    line-height: 35px;
  `)}

  ${media.mobile(css`
    font-size: 24px;
  `)}
`;

const TabList = styled.ul`
  display: flex;
  width: 560px;
  margin: 70px auto 0;

  ${media.tabletSmall(css`
    width: auto;
    flex-wrap: wrap;
  `)}

  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

const TabBlock = styled.div`
  margin-top: 56px;
  margin-bottom: 30px;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;

const Options = styled.span`
  margin: 32px auto 0;
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.blackText};
`;

const Tab = styled.li`
  display: flex;
  text-align: center;
  width: 25%;
  flex-grow: 1;

  &:first-child {
    border-radius: 5px 0 0 5px;
    overflow: hidden;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
    overflow: hidden;
  }

  ${media.tabletSmall(css`
    width: calc(50% - 5px);

    &:nth-child(n) {
      margin: 5px 2.5px;
    }
  `)}
`;

const TabButton = styled.button<{ isActive: boolean }>`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 15px 30px;
  ${(props) =>
    props.isActive
      ? css`
          color: ${colors.white};
          background-color: ${colors.green};
        `
      : css`
          color: #88919d;
          background-color: #f4f4f4;

          &:hover {
            background-color: #e7e7e7;
          }
        `};

  ${media.mobileLargeSmallMedium(css`
    padding: 15px 10px;
    font-size: 12px;
  `)}
`;
