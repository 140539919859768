import React from 'react';

import useSliderMedia from '@/hooks/useSliderMedia';
import BasicListItem from '@/components/__old__/BasicListItem';
import { DEFAULT_SLIDER_CONFIG } from '@/constants/common';
import calendarIconUrl from '@/assets/svg/calendar.svg';
import refundIconUrl from '@/assets/svg/refund.svg';
import apronIconUrl from '@/assets/svg/apron.svg';

import CareerSection, { Item, List, SliderContainer } from './CareerSection';

const ITEM_1 = {
  title: 'Удобный график',
  content: 'Выбирайте подходящее для вас время работы',
  image: { url: calendarIconUrl },
};

const ITEM_2 = {
  title: 'Высокая оплата после каждой уборки',
  content:
    'Ваш доход зависит только от вас. Чем больше работаете, тем больше получаете.',
  image: { url: refundIconUrl },
};

const ITEM_3 = {
  title: 'Обучение и инвентарь',
  content:
    'Команда опытных специалистов проведёт подробный инструктаж и предоставит все обучающие материалы.',
  image: { url: apronIconUrl },
};

const ITEM_LIST = [ITEM_1, ITEM_2, ITEM_3];

function Advantages() {
  const ref = useSliderMedia('(max-width: 1023px)', DEFAULT_SLIDER_CONFIG);
  return (
    <CareerSection title="Преимущества">
      <SliderContainer className="swiper-container" ref={ref}>
        <List className="swiper-wrapper">
          {ITEM_LIST.map((item, index) => (
            <Item className="swiper-slide" key={index}>
              <BasicListItem item={item} />
            </Item>
          ))}
        </List>
        <div className="swiper-pagination" />
      </SliderContainer>
    </CareerSection>
  );
}

export default Advantages;
