import React from 'react';
import styled, { css } from 'styled-components';

import { ButtonLink } from '@/components/__old__/Button';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

export const OrderButton = () => {
  return (
    <Component>
      <SendFormButton href="/order">Оформить подписку</SendFormButton>
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  justify-content: center;

  ${media.tablet(css`
    margin-top: 30px;
  `)}
`;

const SendFormButton = styled(ButtonLink)`
  padding: 15px 30px;
  color: ${colors.white};
  background: ${colors.green};

  &:hover {
    background: ${colors.greenHover};
  }
`;
