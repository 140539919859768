import React from 'react';
import styled, { css } from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function UnderHero() {
  const page = useCurrentPage();
  const templateFields = page.templateFields;

  if (!templateFields) return null;

  return (
    <Component>
      <ContentContainer>
        <Text
          dangerouslySetInnerHTML={{ __html: templateFields.underCoverText }}
        />
      </ContentContainer>
    </Component>
  );
}

export default UnderHero;

const Component = styled.section`
  padding: 80px 0 104px 0;

  ${media.tablet(css`
    padding: 45px 0;
  `)}
`;

const Text = styled.div`
  & > p {
    font-size: 18px;
    line-height: 24px;
    color: ${colors.blackText};
    text-align: center;

    &:not(:first-child) {
      margin-top: 30px;
    }

    strong {
      font-weight: bold;
      color: ${colors.green};
    }

    u {
      text-decoration: none;
      font-weight: 600;
    }

    ${media.tablet(css`
      font-size: 15px;
      line-height: 20px;
    `)}
  }
`;
