import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  useFormikContext,
} from 'formik';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { googleAnalytics, gtag } from '@tager/web-analytics';
import { useModal } from '@tager/web-components';

import AuthModal from '@/modules/Modals/AuthModal';
import { validators } from '@/utils/validation';
import { media } from '@/utils/mixin';
import { OrderRequestBody } from '@/services/requests/requests';
import CounterFormik from '@/components/__old__/Counter/Counter.formik';
import Button from '@/components/__old__/Button';
import { colors } from '@/constants/theme';
import { windowAndBalconyInfoListSelector } from '@/store/reducers/prices';
import { getPrimaryOrderServiceBySystemName } from '@/utils/common';
import { PrimaryOrderServiceType } from '@/typings/model';
import { selectIsUserAuthorized, setPreorderData } from '@/store/reducers/auth';
import { useTypedSelector } from '@/store/store';
import { MaskedTextInputFormik } from '@/components/__old__/MaskedTextInput';
import {
  analyticsOrderStartTriggerEvent,
  facebookPixelTrack,
} from '@/utils/analytics';
import { setOrderStartRequest } from '@/services/requests/orders';
import { selectActiveCity } from '@/store/reducers/cities';

type Props = {
  submitLabel?: React.ReactNode;
  isHero?: boolean;
  phoneError?: string;
  formSubmitLabel: string;
};

export type WindowsFormValues = OrderRequestBody;

function validate(values: WindowsFormValues) {
  return {};
}

function CostRequestFormContainer(props: Props) {
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const city = useTypedSelector(selectActiveCity);

  const isUserAuthorized = useTypedSelector((state) =>
    selectIsUserAuthorized(state)
  );

  const openModal = useModal();
  const router = useRouter();

  function handleSubmit(
    values: WindowsFormValues,
    helpers: FormikHelpers<WindowsFormValues>
  ) {
    googleAnalytics.trackEvent(
      'event',
      props.isHero ? 'window_price' : 'window_order'
    );

    setPhoneError(null);

    if (!isUserAuthorized) {
      if (validators.required(values.phone)) {
        setPhoneError('Введите номер телефона');
        return;
      }
      if (validators.phoneWithCode('+375' + values.phone)) {
        setPhoneError('Неверный номер телефона');
        return;
      }
    }

    analyticsOrderStartTriggerEvent();

    setOrderStartRequest({
      phone: values.phone,
      windows: values.windows || 2,
      balconies: values.balconies || 1,
      city: city ? city.id : null,
    }).then();

    if (isUserAuthorized) {
      const searchParams = new URLSearchParams();
      searchParams.set('windows', String(values.windows));
      searchParams.set('balconies', String(values.balconies));
      router.push({ pathname: '/order', search: searchParams.toString() });
    } else {
      openModal(AuthModal, {
        phone: '+375' + values.phone,
        preorderData: {
          windows: values.windows || 2,
          balconies: values.balconies || 1,
        },
      });
    }
  }

  return (
    <Formik<WindowsFormValues>
      initialValues={{
        phone: '',
        windows: 2,
        balconies: 1,
        rooms: 0,
        bathrooms: 0,
      }}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formProps) => (
        <CostRequestForm
          {...formProps}
          {...props}
          phoneError={phoneError || ''}
          submitLabel={props.formSubmitLabel}
        />
      )}
    </Formik>
  );
}

function CostRequestForm({
  submitLabel,
  isHero,
  phoneError,
}: FormikProps<WindowsFormValues> & Props) {
  const isUserAuthorized = useTypedSelector((state) =>
    selectIsUserAuthorized(state)
  );

  const { values, setFieldValue } = useFormikContext<WindowsFormValues>();
  /** Get Window and Balcony Order Info */
  const windowAndBalconyInfoList = useSelector(
    windowAndBalconyInfoListSelector
  );
  const windowInfoList = getPrimaryOrderServiceBySystemName(
    windowAndBalconyInfoList,
    'WINDOWS'
  );

  const balconyInfoList = getPrimaryOrderServiceBySystemName(
    windowAndBalconyInfoList,
    'BALCONIES'
  );

  function getCounterLabel(
    count: number,
    infoList: PrimaryOrderServiceType
  ): string {
    if (count) {
      return (
        infoList.services.find((service) => service.quantity === count)?.name ??
        ''
      );
    }
    return infoList.emptyValueLabel ?? '';
  }

  useEffect(() => {
    if (
      values &&
      Number(values.windows) < 4 &&
      Number(values.balconies) === 0
    ) {
      setFieldValue('balconies', 1);
    }
  }, [values.windows]);

  return (
    <FormContainer data-test-id="cost-request-form">
      <CalcBlockWrapper>
        <CounterRow>
          {windowInfoList && windowInfoList.services.length ? (
            <CounterFormik
              name="windows"
              getLabel={(count) => {
                return getCounterLabel(count, windowInfoList);
              }}
              min={windowInfoList.emptyValueLabel ? 0 : 2}
              max={windowInfoList?.services.length}
            />
          ) : null}
        </CounterRow>
        <CounterRow>
          {balconyInfoList && balconyInfoList.services.length ? (
            <CounterFormik
              name="balconies"
              getLabel={(count) => {
                return getCounterLabel(count, balconyInfoList);
              }}
              min={
                balconyInfoList.emptyValueLabel
                  ? Number(values.windows) >= 4
                    ? 0
                    : 1
                  : 1
              }
              max={balconyInfoList?.services.length}
            />
          ) : null}
        </CounterRow>
        {!isUserAuthorized ? (
          <InputContainer>
            <MaskedTextInputFormik
              prefix={'+375'}
              type="tel"
              name="phone"
              clearErrorOnChange
              definitions={{ X: /[0-9]/, Х: /[0-9]/, '0': /0/ }}
              mask={'(XX) XXX-XX-XX'}
              placeholder={'Номер телефона'}
              error={phoneError || ''}
            />
          </InputContainer>
        ) : null}
      </CalcBlockWrapper>
      <FormButton type="submit" isHero={!!isHero}>
        {submitLabel}
      </FormButton>
    </FormContainer>
  );
}

const FormContainer = styled(Form)``;

const CalcBlockWrapper = styled.div`
  margin-bottom: 20px;
`;

const CounterRow = styled.div`
  margin-bottom: 5px;

  ${media.laptop(css`
    margin-bottom: 15px;
  `)}
  ${media.tablet(css`
    margin-bottom: 5px;
  `)}
  &:last-child {
    margin-bottom: 0;
  }
`;

const FormButton = styled(Button)<{ isHero: boolean }>`
  font-size: 16px;
  line-height: 20px;
  padding: 20px;
  color: ${colors.white};
  background-color: ${colors.green};
  width: 100%;
  font-weight: 700;

  &:hover {
    background-color: ${colors.greenHover};
  }

  ${(props) =>
    props.isHero
      ? css`
          ${media.tablet(css`
            border-color: ${colors.yellowLight};
            background-color: ${colors.yellowLight};
            color: ${colors.black};

            &:hover {
              background-color: ${colors.yellowLight};
            }
          `)}
        `
      : null}
`;

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export default CostRequestFormContainer;
