import React, { useState } from 'react';

import { createContextHookWithProvider } from '@tager/web-core';

const [useCtx, Provider] =
  createContextHookWithProvider<() => void>('SuccessPageContext');

function SuccessPageProvider({
  children,
}: {
  children: (isSuccess: boolean) => React.ReactNode;
}) {
  const [isSuccess, setSuccess] = useState(false);

  return (
    <Provider value={() => setSuccess(true)}>{children(isSuccess)}</Provider>
  );
}

export const useSuccessPage = useCtx;

export default SuccessPageProvider;
