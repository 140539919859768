import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { RoomPrice } from '@/typings/model';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { useTranslation } from '@/i18n';

import CalculatorForm from './CalculatorFormBottomSection';

type Props = {
  templateFields: Record<string, any>;
};

function BottomSection({ templateFields }: Props) {
  const { t } = useTranslation();

  const firstTitle = templateFields.bottomOrderTitle;
  const secondTitle = templateFields.bottomOrderTitle2;
  const text = templateFields.bottomOrderText;
  const image = templateFields.bottomOrderImage;

  return (
    <Section>
      <ContentContainer>
        <SectionInner>
          {image ? (
            <PhotoContainer>
              <Picture {...convertThumbnailToPictureProps(image)} />
            </PhotoContainer>
          ) : null}
          <Left>
            <Title>
              {firstTitle}
              <TitleLine>{secondTitle}</TitleLine>
            </Title>
            {text ? (
              <InfoText
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            ) : null}
          </Left>
          <FormContainer>
            <CalculatorForm submitLabel={templateFields.footerSubmitLabel} />

            <Agreement>
              Нажимая "Рассчитать стоимость", я даю согласие на{' '}
              <a href="/privacy-policy" target="_blank">
                обработку персональных данных
              </a>
            </Agreement>
          </FormContainer>
        </SectionInner>
      </ContentContainer>
    </Section>
  );
}

const Agreement = styled.span`
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin: 20px -10px 0;
  opacity: 0.75;

  ${media.tablet(css`
    margin-top: 10px;
    color: ${colors.white};
    font-size: 14px;
    line-height: 20px;
  `)}

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

const Section = styled.section`
  padding: 100px 0;
  overflow: hidden;
  ${media.tabletSmall(css`
    background-color: #fbfeff;
  `)}

  ${media.mobile(css`
    padding: 60px 0 40px;
  `)}
`;

const SectionInner = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 280px;

  ${media.tabletSmall(css`
    padding-left: 0;
    flex-wrap: wrap;
    padding-bottom: 235px;
  `)}

  ${media.mobile(css`
    padding-bottom: 0;
  `)}
`;

const PhotoContainer = styled.div`
  position: absolute;
  left: 0;
  top: 70%;
  transform: translateY(-50%);
  max-width: 288px;
  ${media.tabletSmall(css`
    left: 50%;
    top: calc(100% - 200px);
    transform: translateX(-50%);
  `)}

  ${media.mobile(css`
    display: none;
  `)}
`;

const Left = styled.div`
  max-width: 400px;

  ${media.tabletSmall(css`
    max-width: 100%;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  `)}
`;

const Title = styled.h2`
  margin: 0 0 35px;
  font-size: 48px;
  line-height: 51px;
  font-weight: 900;
  color: #383838;

  ${media.mobile(css`
    font-size: 36px;
    line-height: 40px;
  `)}
`;

const TitleLine = styled.span`
  display: flex;
  color: ${colors.green};

  ${media.tabletSmall(css`
    justify-content: center;
  `)}
`;

const InfoText = styled.div`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #88919d;
  span {
    display: inline-flex;
    margin-right: 5px;
    color: ${colors.green};
  }
  ${media.mobile(css`
    font-size: 14px;
    line-height: 21px;
  `)}
`;

const FormContainer = styled.div`
  width: 296px;
  margin: 0 auto;
  ${media.tabletSmall(css``)}
`;

export default BottomSection;
