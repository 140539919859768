import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';
import { ReactComponent as MinusIcon } from '@/assets/svg/counter/minus.svg';
import { ReactComponent as PlusIcon } from '@/assets/svg/counter/plus.svg';

export type CounterProps = {
  getLabel: (count: number) => React.ReactNode;
  value: number;
  onChange: (count: number) => void;
  title?: string;
  max?: number;
  min?: number;
  disabled?: boolean;
};

function Counter({
  getLabel,
  value,
  onChange,
  title,
  max,
  min = 1,
  disabled = false,
}: CounterProps) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <Inner>
        <DecrementButton
          type="button"
          onClick={() => onChange(value - 1)}
          disabled={value === min || disabled}
        >
          <MinusIcon />
        </DecrementButton>
        <LabelText>{getLabel(value)}</LabelText>
        <IncrementButton
          type="button"
          onClick={() => onChange(value + 1)}
          disabled={value === max || disabled}
        >
          <PlusIcon />
        </IncrementButton>
      </Inner>
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
  font-style: normal;
`;

const Inner = styled.div`
  display: flex;
`;
const LabelText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  background-color: ${colors.white};
  flex: 1 1 auto;
`;

const CounterButton = styled.button`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 2px solid ${colors.border};
  background-color: ${colors.white};
  overflow: hidden;
  cursor: pointer;
  color: ${colors.darkText};

  &:hover {
    color: ${colors.green};
  }

  &:disabled {
    cursor: default;
    color: ${colors.blackText};
    opacity: 0.75;
  }
`;

const DecrementButton = styled(CounterButton)`
  width: 62px;
  height: 60px;
  background-color: #efefef;
  position: relative;
  display: block;
  cursor: pointer;
  border-radius: 0;
`;

const IncrementButton = styled(CounterButton)`
  width: 62px;
  height: 60px;
  background-color: #efefef;
  position: relative;
  display: block;
  cursor: pointer;
  border-radius: 0;
`;

export default Counter;
