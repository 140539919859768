import React from 'react';
import styled, { css } from 'styled-components';

import { Nullish } from '@tager/web-core';
import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { ButtonLink } from '@/components/__old__/Button';
import CalculatorForm from '@/modules/Templates/Home/components/CalculatorFormBottomSection';

type Props = {
  title: Nullish<string>;
  image: Nullish<ThumbnailType>;
};

function Hero({ title, image }: Props) {
  return (
    <Section>
      <ContentWrapper>
        <Background>
          <Picture {...convertThumbnailToPictureProps(image)} />
        </Background>
        <ContentContainer>
          <Inner>
            <Content>
              {title ? <Title>{title}</Title> : null}
              <ContentForm>
                <CalculatorForm
                  submitLabel="Рассчитать стоимость"
                  buttonVariant="green"
                />
              </ContentForm>
            </Content>
          </Inner>
        </ContentContainer>
      </ContentWrapper>
    </Section>
  );
}

const Section = styled.section`
  padding-top: 60px;
  overflow: hidden;

  ${media.tablet(css`
    padding-top: 70px;
  `)};

  ${media.tablet(css`
    padding-top: 66px;
  `)};
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;
  }
`;

const Inner = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  min-height: 520px;
  padding: 20px 0;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  text-align: center;
  max-width: 600px;
`;

const Title = styled.h1`
  font-size: 48px;
  line-height: 51px;
  color: ${colors.white};
  font-weight: 900;
  margin: 0 0 60px;

  ${media.mobile(css`
    font-size: 36px;
    line-height: 44px;
  `)};
`;

const OrderButtonLink = styled(ButtonLink)`
  display: inline-flex;
  color: ${colors.white};
  padding: 22px 45px;
  background-color: ${colors.green};

  &:hover {
    background-color: ${colors.greenHover};
  }

  ${media.mobile(css`
    padding: 22px 35px;
    max-width: 280px;
  `)};
`;

const ContentForm = styled.div`
  width: 300px;
  margin: 0 auto;
`;

export default Hero;
