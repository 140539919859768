import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import ExcludedList from '@/components/__old__/AboutCleaning/components/ExcludedList';

type Props = {
  servicesExtendedBottomItems: string;
};

function ExcludedService({ servicesExtendedBottomItems }: Props) {
  return (
    <Wrapper>
      <ExcludedList servicesExtendedBottomItems={servicesExtendedBottomItems} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 60px 15px;
  border-top: 1px solid #eee;

  ${media.mobile(css`
    padding: 40px 20px;
  `)}
`;

export default ExcludedService;
