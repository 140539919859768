import React, { useEffect, useState } from 'react';

import { validators } from '@/utils/validation';
import { authPhoneStart } from '@/services/requests/requests';
import PhoneInput from '@/components/PhoneInput';

import * as S from '../AuthModal.style';

type Props = {
  initialPhone?: string;
  setLoading: (value: boolean) => void;
  onSubmit: (phone: string, session: string, code: string) => void;
};

function LoginFormContainer({ setLoading, onSubmit, initialPhone }: Props) {
  const [phone, setPhone] = useState<string>(initialPhone?.substr(4) || '');

  useEffect(() => {
    if (initialPhone) {
      onStartClick();
    }
  }, []);

  const onStartClick = async () => {
    setLoading(true);

    try {
      const response = await authPhoneStart('+375' + phone);

      onSubmit(
        phone,
        response.data.session,
        response.data.code ? String(response.data.code) : ''
      );
    } catch (e: any) {
      alert('Ошибка отправки SMS - ' + e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <S.InputRow>
        <PhoneInput
          label="Ваш номер телефона:"
          value={phone}
          onChangeValue={setPhone}
        />
      </S.InputRow>
      <S.SignInButtonRow>
        <S.SignInButton
          variant="green"
          type="submit"
          fullWidth={true}
          disabled={validators.phoneWithCode('+375' + phone)}
          onClick={onStartClick}
        >
          Продолжить
        </S.SignInButton>
      </S.SignInButtonRow>
    </>
  );
}

export default LoginFormContainer;
