import React from 'react';
import styled from 'styled-components';

import Layout from '@/components/Layout';
import DefaultHero from '@/components/__old__/DefaultHero';
import useCurrentPage from '@/hooks/useCurrentPage';
import ContentContainer from '@/components/ContentContainer';
import StaticBlock from '@/components/__old__/StaticBlock';
import HowToOrder from '@/components/__old__/SeoComponents/HowToOrder';
import WhatIncluded from '@/components/__old__/SeoComponents/WhatIncluded';
import { HOWTO_ORDER_ITEMS } from '@/components/__old__/SeoComponents/HowToOrder.helpers';
import LandingCalculateForm from '@/components/LandingCalculateForm';

function ServiceTabsContentHelp() {
  const page = useCurrentPage();

  return (
    <Layout
      config={{
        header: {
          colorVariant: 'green',
          isMainBg: false,
        },
      }}
    >
      <DefaultHero title={page?.title} image={page?.image} />
      <WhatIncluded />

      <SectionDefault>
        <ContentContainer>
          <StaticBlock content={page?.body} />
        </ContentContainer>
      </SectionDefault>

      <HowToOrder
        howToTitle={'Очень хочу заказать!\n Что делать?'}
        howToTabs={HOWTO_ORDER_ITEMS}
      />

      <LandingCalculateForm />
    </Layout>
  );
}

const SectionDefault = styled.section`
  padding-top: 60px;
`;

export default ServiceTabsContentHelp;
