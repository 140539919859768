import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

type Props = {
  stepItem: {
    title: string;
    text: React.ReactNode;
  };
};

function StepItem({ stepItem }: Props) {
  return (
    <Item>
      <ItemTop>
        <StepBlock>
          <StepBlockText>{stepItem.title}</StepBlockText>
        </StepBlock>
      </ItemTop>
      <ItemText>{stepItem.text}</ItemText>
    </Item>
  );
}

const Item = styled.div`
  padding: 0 10px;
`;

const ItemTop = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
`;

const StepBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 98px;
  border: 4px solid ${colors.green};
  border-radius: 50%;
`;

const StepBlockText = styled.span`
  position: relative;
  display: inline-flex;
  font-size: 18px;
  line-height: 1;
  font-weight: 900;
  color: ${colors.black};
`;

const ItemText = styled.p`
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.4px;
  text-align: center;

  ${media.tabletSmall(css`
    br {
      display: none;
    }
  `)}
`;

export default StepItem;
