import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import Button from '@/components/__old__/Button';
import { useRepairData } from '@/modules/Templates/Repair/Repair.hooks';

import { SectionTitle } from '../Repair.style';

function OrderSection() {
  const { orderTitle, orderText, orderButtonTitle } = useRepairData();

  if (!orderTitle || !orderText || !orderButtonTitle) {
    return null;
  }

  function handleButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <Section>
      <ContentContainer>
        <SectionInner>
          <Title dangerouslySetInnerHTML={{ __html: orderTitle ?? '' }} />
          <Text dangerouslySetInnerHTML={{ __html: orderText ?? '' }} />
          <ButtonOrder
            onClick={handleButtonClick}
            variant={'yellow'}
            data-testid="scroll-top-button"
          >
            {orderButtonTitle}
          </ButtonOrder>
        </SectionInner>
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  padding: 140px 0;
  color: ${colors.darkText};

  ${media.tablet(css`
    padding: 80px 0;
  `)}

  ${media.mobile(css`
    padding: 60px 0;
  `)}
`;

const Title = styled(SectionTitle)`
  strong {
    font-weight: bold;
    color: ${colors.yellowDark};
  }
`;

const SectionInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1100px;
  margin: 0 auto;

  ${media.tablet(css`
    max-width: 100%;
  `)}
`;

const Text = styled.div`
  font-size: 22px;
  line-height: 1.2;
  max-width: 100%;
  margin-bottom: 50px;

  strong {
    font-weight: normal;
    color: ${colors.yellowDark};
  }

  ${media.tablet(css`
    font-size: 22px;
    margin-bottom: 40px;
  `)}

  ${media.mobile(css`
    font-size: 20px;
    margin-bottom: 30px;
  `)}
`;

const ButtonOrder = styled(Button)`
  font-size: 20px;
  padding: 22px 30px;
  font-weight: 600;
  color: ${colors.blueDark};

  ${media.laptop(css`
    font-size: 18px;
  `)}

  ${media.mobile(css`
    padding: 22px;
  `)}
`;

export default OrderSection;
