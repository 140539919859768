import React from 'react';
import styled, { css } from 'styled-components';

import Layout from '@/components/Layout';
import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import StaticBlock from '@/components/__old__/StaticBlock';

function DefaultTemplate() {
  const page = useCurrentPage();

  return (
    <Layout config={{ header: { colorVariant: 'green', isMainBg: false } }}>
      <SectionDefault>
        <ContentContainer>
          <PageTitle>{page?.title}</PageTitle>
          <StaticBlock content={page?.body} />
        </ContentContainer>
      </SectionDefault>
    </Layout>
  );
}

const SectionDefault = styled.section`
  padding-top: 75px;
  padding-bottom: 150px;

  ${media.mobile(css`
    padding-bottom: 50px;
  `)}
`;

const PageTitle = styled.h1`
  margin: 0 0 30px;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  color: ${colors.blackText};
`;

export default DefaultTemplate;
