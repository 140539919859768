import React from 'react';
import styled, { css } from 'styled-components';

import { ModalProps } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import {
  ModalCloseButton,
  ModalContainer,
} from '@/components/__old__/Modal.style';
import ContentContainer from '@/components/ContentContainer';
import RoomsList from '@/components/__old__/AboutCleaning/components/RoomsList';
import OrderCleaningBlock from '@/components/__old__/AboutCleaning/components/OrderCleaningBlock';
import { ReactComponent as CloseIcon } from '@/assets/svg/close-popup.svg';
import { useTypedSelector } from '@/store/store';
import { selectPageByPath } from '@/store/reducers/tager/pages';

import LinksBlock from './components/LinksBlock';
import ExtraService from './components/ExtraService';
import ExcludedService from './components/ExcludedService';

type Props = ModalProps;

function AboutCleaningModal({ closeModal }: Props) {
  const pageData = useTypedSelector((state) => selectPageByPath(state, '/'));

  const templateFields = pageData?.templateFields;
  if (!templateFields) return null;

  const servicesExtendedTitle = templateFields.servicesExtendedTitle;
  const servicesExtendedText = templateFields.servicesExtendedText;
  const servicesExtendedPrimary: Array<{
    title: string;
    content: string;
    link: string;
    icon: { url: string };
  }> = templateFields.servicesExtendedPrimary;

  const servicesSecondaryTitle = templateFields.servicesSecondaryTitle;
  const servicesExtendedSecondaryItems: Array<{
    title: string;
    content: string;
    link: string;
    icon: { url: string };
  }> = templateFields.servicesExtendedSecondaryItems;
  const servicesExtendedCtaTitle = templateFields.servicesExtendedCtaTitle;
  const servicesExtendedCtaText = templateFields.servicesExtendedCtaText;
  const servicesExtendedCtaButtonLabel =
    templateFields.servicesExtendedCtaButtonLabel;
  const servicesExtendedLinks = templateFields.servicesExtendedLinks;
  const servicesExtendedBottomItems =
    templateFields.servicesExtendedBottomItems;

  return (
    <ModalContainer>
      <ModalCloseButton onClick={closeModal} variant={'green'}>
        <CloseIcon />
      </ModalCloseButton>

      <AboutCleaningContainer>
        <Header>
          {servicesExtendedTitle ? (
            <MainTitle>{servicesExtendedTitle}</MainTitle>
          ) : null}
          {servicesExtendedText ? (
            <SubTitle>{servicesExtendedText}</SubTitle>
          ) : null}
        </Header>
        {servicesExtendedPrimary ? (
          <AboutCleaningBlock>
            <ContentContainer>
              <RoomsList
                servicesExtendedPrimary={servicesExtendedPrimary}
                closeModal={closeModal}
              />
            </ContentContainer>
          </AboutCleaningBlock>
        ) : null}
        {servicesSecondaryTitle || servicesExtendedSecondaryItems ? (
          <AboutCleaningBlock>
            <ExtraService
              servicesSecondaryTitle={servicesSecondaryTitle}
              servicesExtendedSecondaryItems={servicesExtendedSecondaryItems}
              closeModal={closeModal}
            />
          </AboutCleaningBlock>
        ) : null}
        {servicesExtendedCtaTitle ||
        servicesExtendedCtaText ||
        servicesExtendedCtaButtonLabel ? (
          <AboutCleaningBlock>
            <OrderCleaningBlock
              servicesExtendedCtaTitle={servicesExtendedCtaTitle}
              servicesExtendedCtaText={servicesExtendedCtaText}
              servicesExtendedCtaButtonLabel={servicesExtendedCtaButtonLabel}
              closeModal={closeModal}
            />
          </AboutCleaningBlock>
        ) : null}
        {servicesExtendedLinks ? (
          <ContentContainer>
            <LinksBlock
              servicesExtendedLinks={servicesExtendedLinks}
              closeModal={closeModal}
            />
          </ContentContainer>
        ) : null}
        {servicesExtendedBottomItems ? (
          <BottomBlock>
            <ExcludedService
              servicesExtendedBottomItems={servicesExtendedBottomItems}
            />
          </BottomBlock>
        ) : null}
      </AboutCleaningContainer>
    </ModalContainer>
  );
}

const AboutCleaningContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  border-radius: 5px;
  background-color: ${colors.white};
  overflow: hidden;

  ${media.mobile(css`
    padding: 0;
  `)}
`;

const Header = styled.div`
  padding: 0 15px;
  margin-bottom: 60px;

  ${media.mobile(css`
    margin-bottom: 40px;
  `)}
`;

const MainTitle = styled.span`
  display: block;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 20px;

  ${media.mobile(css`
    font-size: 24px;
    line-height: 30px;
  `)}
`;

const SubTitle = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #868f9b;
`;

const AboutCleaningBlock = styled.div`
  margin-bottom: 60px;

  ${media.mobile(css`
    margin-bottom: 40px;
  `)}
`;

const BottomBlock = styled.div`
  margin-top: 20px;
`;

export default AboutCleaningModal;
