import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { ReactComponent as ChatIcon } from '@/assets/svg/messengers/chat.svg';
import { ReactComponent as TmIcon } from '@/assets/svg/messengers/telegram.svg';
import { ReactComponent as ViberIcon } from '@/assets/svg/messengers/viber.svg';
import { ReactComponent as WhatsappIcon } from '@/assets/svg/messengers/whatsapp.svg';
import { ReactComponent as MessengerIcon } from '@/assets/svg/messengers/messenger.svg';
import useSettingItem from '@/hooks/useSettingItem';

function Messengers() {
  const [isHidden, setHidden] = useState(true);

  function toggleHidden() {
    setHidden((prev) => !prev);
  }

  const telegram = useSettingItem('MESSENGER_TELEGRAM');
  const viber = useSettingItem('MESSENGER_VIBER');
  const whatsapp = useSettingItem('MESSENGER_WHATSAPP');
  const facebook = useSettingItem('MESSENGER_FACEBOOK');

  return telegram || viber || whatsapp || facebook ? (
    <MessengersBlock>
      <MessengersInner>
        <Header>
          <MainButton onClick={toggleHidden} isActive={!isHidden}>
            {isHidden ? <ChatIcon /> : null}
          </MainButton>
        </Header>
        <Body isHidden={isHidden}>
          {telegram ? (
            <MessengerItem>
              <TelegramLink href={`tg://resolve?domain=${telegram}`}>
                <TmIcon />
              </TelegramLink>
            </MessengerItem>
          ) : null}
          {viber ? (
            <MessengerItem>
              <ViberLink href={`viber://chat?number=${viber}`}>
                <ViberIcon />
              </ViberLink>
            </MessengerItem>
          ) : null}
          {whatsapp ? (
            <MessengerItem>
              <WhatsappLink href={`whatsapp://send?phone=${whatsapp}`}>
                <WhatsappIcon />
              </WhatsappLink>
            </MessengerItem>
          ) : null}
          {facebook ? (
            <MessengerItem>
              <MessengerLink href={`https://www.messenger.com/t/${facebook}`}>
                <MessengerIcon />
              </MessengerLink>
            </MessengerItem>
          ) : null}
        </Body>
      </MessengersInner>
    </MessengersBlock>
  ) : null;
}

const MessengersBlock = styled.div`
  position: fixed;
  bottom: 26px;
  right: 26px;
  z-index: 30;

  ${media.mobileSmall(css`
    right: 22px;
  `)};
`;

const MessengersInner = styled.div`
  position: relative;
`;

const Header = styled.div``;

const mainButtonCss = css`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
`;

const buttonLineCss = css`
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 2px;
  background-color: ${colors.border};
  opacity: 0;
  visibility: hidden;
  transition: 0.45s ease;
`;

const MainButton = styled.button<{ isActive: boolean }>`
  ${mainButtonCss};
  transition: 0.3ms ease;

  &:before {
    ${buttonLineCss};
    transform: translate(-50%, -50%) rotate(45deg) translateZ(0);
  }

  &:after {
    ${buttonLineCss};
    transform: translate(-50%, -50%) rotate(-45deg) translateZ(0);
  }

  ${(props) =>
    props.isActive
      ? css`
          background-color: ${colors.white};
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);

          &:before {
            visibility: visible;
            opacity: 1;
            transform: translate(-50%, -50%) rotate(315deg) translateZ(0);
          }

          &:after {
            visibility: visible;
            opacity: 1;
            transform: translate(-50%, -50%) rotate(225deg) translateZ(0);
          }
        `
      : css`
          background-color: ${colors.green};
          box-shadow: 0 2px 10px 0 transparent;

          ${media.mobile(
            css`
              background-color: ${colors.yellowLight};
            `
          )};
        `}
`;

const Body = styled.div<{ isHidden: boolean }>`
  position: absolute;
  bottom: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  transition: 0.4s ease;

  ${(props) =>
    props.isHidden
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : css`
          opacity: 1;
          visibility: visible;
        `};
`;

const MessengerItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const messengerLinkCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 2px 10px 0 transparent, 0 2px 15px 0 rgba(0, 0, 0, 0.25);

  &:hover {
    transform: scale(1.1);
  }
`;

const TelegramLink = styled.a`
  ${messengerLinkCss};
  background-color: #33b1e1;
`;

const ViberLink = styled.a`
  ${messengerLinkCss};
  background-color: #665cac;
`;

const WhatsappLink = styled.a`
  ${messengerLinkCss};
  background-color: #02d051;
`;

const MessengerLink = styled.a`
  ${messengerLinkCss};
  background-color: #0084ff;
`;

export default Messengers;
