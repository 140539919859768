import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { cookie } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { citiesActions, selectCities } from '@/store/reducers/cities';
import Link from '@/components/Link';
import { getPageBaseUrl } from '@/utils/regions';
import { City } from '@/services/requests/misc';

import { ReactComponent as ArrowIcon } from '../svg/ArrowIcon.svg';
import { ReactComponent as LocationIcon } from '../svg/LocationIcon.svg';

type Props = {
  mode: 'DESKTOP' | 'MOBILE';
};

const HeaderLocation: React.FC<Props> = ({ mode }) => {
  const dispatch = useTypedDispatch();
  const router = useRouter();
  const cities = useTypedSelector(selectCities);

  const [activeCityId, setActiveCityId] = useState<number | null>(null);
  const [activeCityName, setActiveCityName] = useState<string>('');

  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  useEffect(() => {
    const cookieCityValue = cookie.get('cityId');

    if (cities.length === 0) {
      setActiveCityName('');
      setActiveCityId(null);
    } else {
      const activeCityId = cookieCityValue ? +cookieCityValue : cities[0].id;

      const activeCityName =
        cities.find((item) => item.id === activeCityId)?.name || '';

      setActiveCityName(activeCityName);
      setActiveCityId(activeCityId);
    }
  }, [cities]);

  if (!activeCityId || !cities.length) return null;

  const onSelect = (city: City) => {
    cookie.set('cityId', String(city.id), undefined, 30);
    setMenuVisible(false);
    dispatch(citiesActions.setActiveCity(city));
    return true;
  };

  const onMouseOver = () => {
    if (mode === 'DESKTOP') {
      setMenuVisible(true);
    }
  };

  const onMouseOut = () => {
    if (mode === 'DESKTOP') {
      setMenuVisible(false);
    }
  };

  const toggle = () => {
    if (mode === 'MOBILE') {
      setMenuVisible(!menuVisible);
    }
  };

  return (
    <Component onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <Header onClick={() => toggle()}>
        <LocationIcon />
        <Value>г. {activeCityName}</Value>
      </Header>
      <MenuOuter visible={menuVisible}>
        <Menu>
          {cities.map((city) => (
            <MenuItem>
              {activeCityId === city.id ? (
                <MenuItemSelected>{city.name}</MenuItemSelected>
              ) : (
                <MenuItemLink
                  to={getPageBaseUrl(router.asPath, cities)}
                  city={city}
                  onClick={() => onSelect(city)}
                >
                  {city.name}
                </MenuItemLink>
              )}
            </MenuItem>
          ))}
        </Menu>
      </MenuOuter>
    </Component>
  );
};

const MenuOuter = styled.div<{ visible: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 220px;
  padding-top: 10px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.3s opacity ease;

  ${(props) =>
    props.visible
      ? css`
          opacity: 1;
          visibility: visible;
          pointer-events: visible;
        `
      : null}

  ${media.mobile(css`
    min-width: 100vw;
    left: -10px;
    transform: none;
  `)}
`;

const Menu = styled.ul`
  background-color: ${colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 5px 0 10px;
  border: 1px solid ${colors.blockBorder};
  width: 100%;
  transition: 0.2s;

  ${media.mobile(css`
    border: 0 none;
    border-top: 1px solid ${colors.blockBorder};
    border-radius: 0;
  `)}
`;

const MenuItem = styled.li``;

const MenuItemSelected = styled.span`
  padding: 10px 40px;
  text-align: center;
  display: block;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: ${colors.green};
`;

const MenuItemLink = styled(Link)`
  padding: 10px 40px;
  text-align: center;
  display: block;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: ${colors.green};
    color: ${colors.white};
  }
`;

const Component = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-top: -5px;

  ${media.mobile(css`
    margin: 5px 0 0 0;
  `)}
`;

const Header = styled.div`
  display: flex;
  cursor: pointer;
  color: ${colors.black300};
  align-items: center;
  position: relative;

  svg {
    &:first-child {
      margin-right: 5px;
      width: 27px;
      height: auto;
      transform: translateY(-1px);
    }

    &:last-child {
      margin-left: 8px;
    }

    ${media.mobile(css`
      display: none;
    `)}
  }

  svg {
    transition: 0.3s all ease;
    transform: translateY(1px);
    height: auto;
  }

  ${media.mobile(css`
    padding-right: 12px;

    &:after {
      position: absolute;
      content: '';
      right: 0;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid ${colors.black300};
    }
  `)}
`;

const Value = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: ${colors.black300};

  ${media.mobile(css`
    font-size: 12px;
  `)}
`;

export default HeaderLocation;
