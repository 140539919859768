import { Nullish } from '@tager/web-core';

import { PageTemplateType } from '@/typings/model';

import useCurrentPage from './useCurrentPage';

function useCurrentPageTemplate(): Nullish<PageTemplateType> {
  try {
    const template = useCurrentPage()?.template ?? null;
    return template as PageTemplateType;
  } catch (e) {
    return null;
  }
}

export default useCurrentPageTemplate;
