import React from 'react';
import styled from 'styled-components';

import CompaniesReviews from '@/components/__old__/CompaniesReviews';
import Layout from '@/components/Layout';
import SuccessPageProvider from '@/components/__old__/SuccessPageProvider';
import Order from '@/modules/Templates/Repair/components/Order';
import useCurrentPage from '@/hooks/useCurrentPage';

import Hero from './components/Hero';
import HowItWorks from './components/HowItWorks';
import WhatIsIncluded from './components/WhatIsIncluded';
import Equipment from './components/Equipment';
import OrderSection from './components/OrderSection';

function Repair() {
  const templateFields = useCurrentPage().templateFields;

  if (!templateFields) return null;

  return (
    <Layout
      config={{
        header: {
          colorVariant: 'yellow',
          logoText: 'Уборка после ремонта',
          isRepairPageInfo: true,
          isMainBg: false,
        },
      }}
    >
      <SuccessPageProvider>
        {(isSuccess) =>
          isSuccess ? (
            <Order />
          ) : (
            <Wrapper>
              <Hero />
              <HowItWorks />
              <WhatIsIncluded />
              <Equipment />
              <CompaniesReviews companyList={templateFields.press} />
              <OrderSection />
            </Wrapper>
          )
        }
      </SuccessPageProvider>
    </Layout>
  );
}

const Wrapper = styled.div``;

export default Repair;
