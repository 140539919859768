import React from 'react';
import styled, { css } from 'styled-components';
import { NextRouter, useRouter } from 'next/router';

import { MenuItemType } from '@tager/web-modules';
import { dividePathnameAndSearch, Nullish } from '@tager/web-core';
import { CustomLinkProps } from '@tager/web-components';

import Link from '@/components/Link';
import { ThemeColor } from '@/typings/common';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { getColorByVariant } from '@/utils/common';

function isLinkActive(linkUrl: string, router: NextRouter): boolean {
  const [currentPath] = dividePathnameAndSearch(router.asPath);
  const [linkPath] = dividePathnameAndSearch(linkUrl);

  return currentPath === linkPath;
}

function parseMenuItemLabel(label: string): {
  text: string;
  badge: Nullish<string>;
} {
  const [text, badge] = label.split('/');
  return { text, badge };
}

type Props = {
  colorVariant: ThemeColor;
  menuList: Array<MenuItemType>;
};

function MainMenu({ colorVariant, menuList }: Props) {
  const router = useRouter();

  return (
    <Nav>
      <NavList>
        {menuList.map((menuItem) => (
          <NavItem variant={colorVariant} key={menuItem.id}>
            <Link
              to={menuItem.link}
              isActive={() => isLinkActive(menuItem.link ?? '', router)}
              target={menuItem.isNewTab ? '_blank' : undefined}
            >
              {(linkProps) => {
                const { text, badge } = parseMenuItemLabel(menuItem.label);
                return (
                  <NavLink variant={colorVariant} {...linkProps}>
                    {text}
                    {badge ? (
                      <Badge variant={colorVariant}>{badge}</Badge>
                    ) : null}
                  </NavLink>
                );
              }}
            </Link>
          </NavItem>
        ))}
      </NavList>
    </Nav>
  );
}

export type ColorVariant = ThemeColor;

const Nav = styled.nav`

${media.tablet(css`
  overflow: scroll hidden;
  margin: -20px -20px 0 -18px;
  padding-left: 20px;
  padding-top: 20px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`)}
}`;

const NavList = styled.ul`
  display: flex;
  justify-content: flex-start;
`;

const Badge = styled.span<{ variant: ColorVariant }>`
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  background-color: ${(props) => getColorByVariant(props.variant)};
  border-radius: 3px;
  font-size: 8px;
  padding: 3px 2px 2px 3px;
  position: absolute;
  right: 0;
  top: -15px;
  letter-spacing: normal;
`;

const NavItem = styled.li<{ variant: ColorVariant }>`
  font-weight: 700;
  letter-spacing: 1.55px;
  padding-bottom: 6px;
  position: relative;
  &:not(:last-child) {
    margin-right: 28px;
  }
  &:last-child {
    padding-right: 28px;
    ${Badge} {
      right: 28px;
    }
  }
`;

const NavLink = styled.a<CustomLinkProps & { variant: ColorVariant }>`
  white-space: nowrap;
  text-transform: uppercase;
  &:hover {
    color: ${(props) => getColorByVariant(props.variant)};
  }
  ${(props) =>
    props.isActive
      ? css`
          color: ${getColorByVariant(props.variant)};
          position: relative;
        `
      : null}
  ${media.mobile(css`
    &:hover {
      color: ${colors.blackText};
    }
  `)};
`;

export default MainMenu;
