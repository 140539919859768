import React from 'react';
import styled, { css } from 'styled-components';

import useSliderMedia from '@/hooks/useSliderMedia';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { DEFAULT_SLIDER_CONFIG } from '@/constants/common';

import StepItem from './StepItem';

function StepList({
  howToItems,
}: {
  howToItems: Array<{ title: string; text: string }>;
}) {
  const ref = useSliderMedia('(max-width: 767px)', {
    ...DEFAULT_SLIDER_CONFIG,
    centeredSlides: true,
  });
  return (
    <SliderContainer className="swiper-container" ref={ref}>
      <List className="swiper-wrapper">
        {howToItems?.map((item, index) => (
          <Item key={index} className="swiper-slide">
            <StepItem stepItem={item} />
          </Item>
        ))}
      </List>
      <div className="swiper-pagination" />
    </SliderContainer>
  );
}

const SliderContainer = styled.div`
  .swiper-pagination {
    display: none;
    position: relative;
    bottom: 0;
    margin-top: 30px;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;

      &-active {
        background-color: ${colors.green};
      }
    }

    ${media.mobile(css`
      display: block;
    `)}
  }
`;

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  counter-reset: section;
`;

const Item = styled.li`
  width: calc(33.333% - 20px);

  &:not(:last-child) {
    margin-right: 30px;
  }

  ${media.mobile(css`
    width: 100%;

    &:not(:last-child) {
      margin-right: 0;
    }
  `)}
`;

export default StepList;
