import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { Form, Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import { TFunction } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Nullable } from '@tager/web-core';
import { useModal } from '@tager/web-components';

import AuthModal from '@/modules/Modals/AuthModal';
import { validators } from '@/utils/validation';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Button from '@/components/__old__/Button';
import CounterFormik from '@/components/__old__/Counter/Counter.formik';
import { useTranslation } from '@/i18n';
import useSettingItem from '@/hooks/useSettingItem';
import { roomAndBathroomInfoListSelector } from '@/store/reducers/prices';
import { getPrimaryOrderServiceBySystemName } from '@/utils/common';
import { useTypedSelector } from '@/store/store';
import { selectIsUserAuthorized } from '@/store/reducers/auth';
import { MaskedTextInputFormik } from '@/components/__old__/MaskedTextInput';
import { analyticsOrderStartTriggerEvent } from '@/utils/analytics';
import { setOrderStartRequest } from '@/services/requests/orders';
import { selectActiveCity } from '@/store/reducers/cities';
import { ButtonVariant } from '@/components/__old__/Button/Button.style';

type Props = {
  submitLabel: string;
  buttonVariant?: ButtonVariant;
};

export type FormValues = {
  phone: string;
  code: string;
  rooms?: number;
  bathrooms?: number;
  windows?: number;
  balconies?: number;
};

function validate(values: FormValues, t: TFunction<string>) {
  const errors: FormikErrors<FormikValues> = {};

  if (validators.required(values.phone)) {
    errors.phone = t('empty_phone');
  }

  return errors;
}

function CalculatorFormContainer(props: Props) {
  const { t } = useTranslation();
  const phonePrefix = useSettingItem('LAYOUT_PHONE_PREFIX');

  return (
    <Formik<FormValues>
      initialValues={{
        phone: '',
        code: '',
        rooms: 1,
        bathrooms: 1,
        windows: 0,
        balconies: 0,
      }}
      onSubmit={() => {}}
      validate={(values) => validate(values, t)}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formProps) => (
        <CalculatorForm {...formProps} {...props} phonePrefix={phonePrefix} />
      )}
    </Formik>
  );
}

function CalculatorForm({
  isSubmitting,
  submitLabel,
  values,
  buttonVariant = 'yellow',
}: FormikProps<FormValues> & Props & { phonePrefix: Nullable<string> }) {
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const city = useTypedSelector(selectActiveCity);

  const isUserAuthorized = useTypedSelector((state) =>
    selectIsUserAuthorized(state)
  );

  const router = useRouter();

  /** Get Room and Bathroom Order Info */

  const roomAndBathroomInfoList = useSelector(roomAndBathroomInfoListSelector);

  const roomInfoList = getPrimaryOrderServiceBySystemName(
    roomAndBathroomInfoList,
    'ROOMS'
  );

  const bathroomInfoList = getPrimaryOrderServiceBySystemName(
    roomAndBathroomInfoList,
    'BATHROOMS'
  );

  const openModal = useModal();

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setPhoneError(null);

    if (!isUserAuthorized) {
      if (validators.required(values.phone)) {
        setPhoneError('Введите номер телефона');
        return;
      }

      if (validators.phoneWithCode('+375' + values.phone)) {
        setPhoneError('Неверный номер телефона');
        return;
      }
    }

    analyticsOrderStartTriggerEvent();

    setOrderStartRequest({
      phone: values.phone,
      rooms: values.rooms || 2,
      bathrooms: values.bathrooms || 1,
      city: city ? city.id : null,
    }).then();

    if (isUserAuthorized) {
      const searchParams = new URLSearchParams();
      searchParams.set('rooms', String(values.rooms));
      searchParams.set('bathrooms', String(values.bathrooms));
      router.push({ pathname: '/order', search: searchParams.toString() });
    } else {
      openModal(AuthModal, {
        phone: '+375' + values.phone,
        preorderData: {
          rooms: values.rooms || 2,
          bathrooms: values.bathrooms || 1,
        },
      });
    }
  };

  return (
    <FormContainer data-testid="cost-request-form">
      <CalcBlockWrapper>
        <CounterRow>
          {roomInfoList && roomInfoList.services.length ? (
            <CounterFormik
              name="rooms"
              getLabel={(count) => {
                if (count) {
                  return roomInfoList.services.find(
                    (service) => service.quantity === count
                  )?.name;
                }
                return roomInfoList.emptyValueLabel;
              }}
              max={roomInfoList?.services.length}
              min={1}
            />
          ) : null}
        </CounterRow>
        <CounterRow>
          {bathroomInfoList && bathroomInfoList.services.length ? (
            <CounterFormik
              name="bathrooms"
              getLabel={(count) => {
                if (count) {
                  return bathroomInfoList.services.find(
                    (service) => service.quantity === count
                  )?.name;
                }
                return bathroomInfoList.emptyValueLabel;
              }}
              max={bathroomInfoList?.services.length}
              min={1}
            />
          ) : null}
        </CounterRow>
        {!isUserAuthorized ? (
          <MaskedTextInputFormik
            prefix={'+375'}
            type="tel"
            name="phone"
            clearErrorOnChange
            definitions={{ X: /[0-9]/, Х: /[0-9]/, '0': /0/ }}
            mask={'(XX) XXX-XX-XX'}
            placeholder={'Номер телефона'}
            error={phoneError || ''}
          />
        ) : null}
      </CalcBlockWrapper>
      <FormButton
        fullWidth={true}
        variant={buttonVariant}
        type="submit"
        disabled={isSubmitting}
        onClick={onSubmit}
      >
        {submitLabel}
      </FormButton>
    </FormContainer>
  );
}

const FormContainer = styled(Form)`
  color: ${colors.blackText};
  background-color: transparent;
`;

const CalcBlockWrapper = styled.div`
  margin-bottom: 25px;
`;

const CounterRow = styled.div`
  margin-bottom: 5px;

  ${media.laptop(css`
    margin-bottom: 15px;
  `)}
  ${media.tablet(css`
    margin-bottom: 5px;
  `)}
  &:last-child {
    margin-bottom: 0;
  }
`;

const FormButton = styled(Button)`
  line-height: 20px;
  padding: 20px;
  color: ${colors.black};
  font-weight: 700;
`;

export default CalculatorFormContainer;
