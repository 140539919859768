import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import Button from '@/components/__old__/Button';

type Props = {
  title: string;
  children?: React.ReactNode;
};

function CareerSection({ title, children }: Props) {
  function handleButtonClick() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    const nameField = document.getElementById('full-name');
    if (!nameField) return;
    setTimeout(() => {
      nameField.focus();
    }, 500);
  }

  return (
    <Section>
      <ContentContainer>
        <SectionTop>
          <SectionTitle>{title}</SectionTitle>
        </SectionTop>
        {children}
        <SectionBottom>
          <ApplyBidButton onClick={handleButtonClick}>
            Подать заявку
          </ApplyBidButton>
        </SectionBottom>
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  padding: 60px 0;
  overflow: hidden;
`;

const SectionTop = styled.div`
  margin-bottom: 60px;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 32px;
  line-height: 45px;
  font-weight: 500;
  letter-spacing: -0.8px;
  margin: 0;
  color: ${colors.black};
`;

const SectionBottom = styled.div`
  margin-top: 40px;
  text-align: center;
`;

const ApplyBidButton = styled(Button)`
  background-color: ${colors.yellowLight};
  padding: 22px 40px;

  &:hover {
    background-color: ${colors.yellow};
  }
`;

export const SliderContainer = styled.div`
  .swiper-pagination {
    display: none;
    position: relative;
    bottom: 0;
    margin-top: 30px;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;

      &-active {
        background-color: ${colors.green};
      }
    }

    ${media.tabletSmall(css`
      display: block;
    `)}
  }
`;

export const List = styled.ul`
  display: flex;
  justify-content: space-between;
`;

export const Item = styled.li`
  width: 33.3333%;
  padding: 0 20px;

  ${media.tabletSmall(css`
    width: 100%;
    padding: 0;
  `)}
`;

export default CareerSection;
