import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useModal } from '@tager/web-components';
import { cookie } from '@tager/web-core';

import useSettingItem from '@/hooks/useSettingItem';
import { useTypedSelector } from '@/store/store';
import { selectIsUserAuthorized } from '@/store/reducers/auth';
import OrderModalView from '@/modals/OrderModal/OrderModal.view';

const OrderModal: React.FC = () => {
  const openModal = useModal();
  const router = useRouter();

  const enabled = useSettingItem('ORDER_MODAL_ENABLED');
  const title = useSettingItem('ORDER_MODAL_TITLE');
  const text = useSettingItem('ORDER_MODAL_TEXT');
  const buttonLabel = useSettingItem('ORDER_MODAL_BUTTON_LABEL');
  const promocode = useSettingItem('ORDER_MODAL_PROMOCODE');

  const isUserAuthorized = useTypedSelector((state) =>
    selectIsUserAuthorized(state)
  );

  const isEnabled = (): boolean => {
    if (!enabled || isUserAuthorized) {
      return false;
    }

    if (
      router.asPath.startsWith('/order') ||
      router.asPath.startsWith('/personal')
    ) {
      return false;
    }

    if (cookie.get('order_modal_opened')) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!isEnabled()) {
      return;
    }

    setTimeout(() => {
      cookie.set('order_modal_opened', '1', undefined, 30);

      openModal(OrderModalView, {
        title: title || '',
        text: text || '',
        buttonLabel: buttonLabel || '',
        onSubmit: (phone: string) => {
          router.push({
            pathname: `/order`,
            query: {
              phone,
              promocode: promocode || null,
            },
          });
        },
      });
    }, 1000);
  });

  return null;
};

export default OrderModal;
