import React from 'react';
import styled, { css } from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function Ordering() {
  const page = useCurrentPage();
  const templateFields = page.templateFields;

  if (!templateFields) return null;

  return (
    <Component>
      <ContentContainer>
        <Text
          dangerouslySetInnerHTML={{ __html: templateFields.orderingText }}
        />
      </ContentContainer>
    </Component>
  );
}

export default Ordering;

const Component = styled.section`
  padding: 104px 0;

  ${media.tablet(css`
    padding: 45px 0;
  `)}
`;

const Text = styled.div`
  & > p {
    font-size: 18px;
    line-height: 24px;
    color: ${colors.blackText};
    max-width: 800px;
    text-align: center;
    margin: 0 auto;

    &:not(:first-child) {
      margin-top: 30px;
    }

    a {
      font-weight: 500;
      color: ${colors.green};
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid ${colors.green};
      }
    }

    strong {
      text-decoration: none;
      font-weight: bold;
    }

    ${media.tablet(css`
      font-size: 15px;
      line-height: 20px;
    `)}
  }
`;
