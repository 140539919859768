import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { PlusesCardProps } from '@/modules/Templates/Subscription/components/Pluses/Card/PlusesCard.types';
import { media } from '@/utils/mixin';

import PlusesCard from './Card';

function Pluses() {
  const page = useCurrentPage();
  const templateFields = page.templateFields;

  if (!templateFields) return null;

  return (
    <Component>
      <ContentContainer>
        <Title>{templateFields.plusesSubscriptionTitle}</Title>
        <Cards>
          {templateFields.plusesSubscriptionItems.map(
            ({ image, description }: PlusesCardProps, index: number) => (
              <PlusesCard image={image} description={description} key={index} />
            )
          )}
        </Cards>
      </ContentContainer>
    </Component>
  );
}

export default Pluses;

const Component = styled.section`
  padding-bottom: 104px;

  ${media.tablet(css`
    padding-bottom: 40px;
  `)}
`;

const Title = styled.span`
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 32px;
  line-height: 24px;
  font-weight: 900;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
  margin-top: 40px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
  `)}

  ${media.mobileLargeSmallMedium(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;
