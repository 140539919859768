import cleanerUrl from '@/assets/images/office/cleaner.png';
import cleanerUrl2x from '@/assets/images/office/cleaner@2x.png';
import cleanerUrlWebp from '@/assets/images/office/cleaner.webp';
import cleanerUrlWebp2x from '@/assets/images/office/cleaner@2x.webp';
import apalonSrc from '@/assets/images/companies/apalon.png';
import epamSrc from '@/assets/images/companies/epam.png';
import itraSrc from '@/assets/images/companies/itra.png';
import viberSrc from '@/assets/images/companies/viber.png';

const EPAM_ITEM = {
  url: 'https://careers.epam.by/',
  image: { url: epamSrc },
};

const ITRA_ITEM = {
  url: 'https://itransition.by/',
  image: { url: itraSrc },
};

const VIBER_ITEM = {
  url: 'https://www.viber.com/ru/',
  image: { url: viberSrc },
};

const APALON_ITEM = {
  url: 'https://www.apalon.com/',
  image: { url: apalonSrc },
};

export const COMPANIES_ITEMS = [EPAM_ITEM, ITRA_ITEM, VIBER_ITEM, APALON_ITEM];

export const OFFICE_CLEANER_URLS = {
  src: cleanerUrl,
  src2x: cleanerUrl2x,
  srcWebp: cleanerUrlWebp,
  srcWebp2x: cleanerUrlWebp2x,
};

const SERVICE_ITEM1 = {
  title: 'Уборка офисов',
  text: 'Без уборщиц в штате, оплата 1 раз в месяц',
  linkComponents: [
    {
      label: 'Регулярная уборка офиса',
      link: '/office',
    },
    {
      label: 'Бесплатная дезинфекция',
      link: '/office',
    },
  ],
};

const SERVICE_ITEM2 = {
  title: 'Уборка офисов после ремонта',
  text: 'Отмоем все следы и подготовим к переезду',
  linkComponents: [
    {
      label: 'Необходимый инвентарь с собой',
      link: '/office/after-repair',
    },
    {
      label: 'Бригада профессионалов',
      link: '/office/after-repair',
    },
    {
      label: 'Специальное оборудование',
      link: '/office/after-repair',
    },
  ],
};

const SERVICE_ITEM3 = {
  title: 'Мытье окон в офисе и магазинах',
  text: 'Окна или витрины будут кристально чистыми',
  linkComponents: [
    {
      label: 'Моем окна с двух сторон',
      link: '/office/windows',
    },
    {
      label: 'Отмываем фурнитуру',
      link: '/office/windows',
    },
    {
      label: 'Специальное оборудование',
      link: '/office/windows',
    },
  ],
};

export const SERVICE_ITEMS_LIST = [SERVICE_ITEM1, SERVICE_ITEM2, SERVICE_ITEM3];

const ADVANTAGE_ITEM_1 = {
  title: 'Надежность и опыт',
  text: 'Мы сделали более 50 тыс. уборок квартир: 98% из них оценены на хорошо и отлично. Такой же стандарт качества мы сохраняем в B2B уборках.',
};

const ADVANTAGE_ITEM_2 = {
  title: 'Прозрачная стоимость',
  text: 'Вы оплачиваете услуги по факту или раз в месяц (при регулярной уборке). Наша цена всегда честная, а весь сервис полностью прозрачен.',
};

const ADVANTAGE_ITEM_3 = {
  title: 'Обученные клинеры',
  text: 'Все клинеры проходят строгий отбор и 3х этапное обучение, далее сдают экзамены и проходят практику. Лишь 1 из 10 кандидатов получает работу.',
};

const ADVANTAGE_ITEM_4 = {
  title: 'Постоянные сотрудники',
  text: 'Вам не нужно держать в штате уборщицу или платить «серым» уборщикам. Вы получите лучший сервис, со всеми документами и оплатой через Р/С.',
};

const ADVANTAGE_ITEM_5 = {
  title: 'Обслуживание клиентов',
  text: 'Мы всегда на связи: email, телефон, Slack, Telegram, Facebook и тд. Любой ваш вопрос получит максимально быстрое решение.',
};

export const ADVANTAGE_ITEM_LIST = [
  ADVANTAGE_ITEM_1,
  ADVANTAGE_ITEM_2,
  ADVANTAGE_ITEM_3,
  ADVANTAGE_ITEM_4,
  ADVANTAGE_ITEM_5,
];

export const ALL_SERVICES_LIST = [
  'уборка офисов',
  'уборка офиса после ремонта',
  'мытье окон в офисе',
  'уборка магазинов в ТЦ',
  'уборка уличных магазинов',
  'уборка продуктовых магазинов',
  'уборка ресторанов',
  'уборка тренажерных залов',
  'уборка салонов красоты',
  'все виды уборки квартир',
  'уборка частных домов',
  'химчистка мебели и ковров',
  'выгул и передержка собак',
];

const STEP_ITEM_1 = {
  title: 'Оформляем договор',
  list: [
    'проводим бесплатную тестовую уборку',
    'готовим понятный договор (с НДС)',
  ],
};

const STEP_ITEM_2 = {
  title: 'Фиксируем цену',
  list: [
    'фиксируем цену (она не будет меняться)',
    'определяем, как часто будут уборки',
    'определяем для вас время',
  ],
};

export const STEP_ITEMS_LIST = [STEP_ITEM_1, STEP_ITEM_2];

const TYPE_OF_CLEANING_ITEM_1 = {
  title: {
    type: 'blue',
    label: 'Регулярная уборка офиса',
  },
  description:
    'Клинер приходит каждый день утром или вечером, чтобы навести чистоту и порядок. К приходу сотрудников клинер вымоет полы и санузел, вытрет пыль, выбросит мусор и помоет посуду.',
};

const TYPE_OF_CLEANING_ITEM_2 = {
  title: {
    type: 'dark',
    label: 'Уборка офиса после ремонта',
  },
  description:
    'На уборку приедет команда клинеров с профессиональной техникой под руководством старшего клинера. Данный тип уборки подойдет для ремонта,переезда и подобного. Мы уберем строительную пыль и загрязнения.',
};

const TYPE_OF_CLEANING_ITEM_3 = {
  title: {
    type: 'yellow',
    label: 'Мытье окон в офисе',
  },
  description:
    'Вымоем стёкла с обеих сторон и уберём разводы, отмоем рамы и протрём подоконники',
};

export const TYPE_OF_CLEANING_LIST = [
  TYPE_OF_CLEANING_ITEM_1,
  TYPE_OF_CLEANING_ITEM_2,
  TYPE_OF_CLEANING_ITEM_3,
];
