import React from 'react';
import styled, { css } from 'styled-components';

import useSliderMedia from '@/hooks/useSliderMedia';
import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { DEFAULT_SLIDER_CONFIG } from '@/constants/common';

import { Section, SectionTitle } from '../Home.style';

import Employee from './Employee';

type Props = {
  templateFields: Record<string, any>;
};

function BestOfTheMonth({ templateFields }: Props) {
  const ref = useSliderMedia('(max-width: 767px)', {
    ...DEFAULT_SLIDER_CONFIG,
    centeredSlides: true,
  });
  const cleanersTitle = templateFields.cleanersTitle;
  const cleanersText = templateFields.cleanersText;
  const bestCleaners: Array<{
    image: { url: string };
    name: string;
    rating: string;
    text: string;
  }> = templateFields.cleanersItems;

  return cleanersTitle ||
    cleanersText ||
    (bestCleaners && bestCleaners.length) ? (
    <SectionOverflow>
      <ContentContainer>
        {cleanersTitle ? <Title>{cleanersTitle}</Title> : null}
        {cleanersText ? (
          <SectionIntro
            dangerouslySetInnerHTML={{
              __html: cleanersText,
            }}
          />
        ) : null}
        {bestCleaners && bestCleaners.length ? (
          <SliderContainer className="swiper-container" ref={ref}>
            <List className="swiper-wrapper">
              {bestCleaners.map((employeeItem, index) => (
                <Item key={index} className="swiper-slide">
                  <Employee employeeItem={employeeItem} />
                </Item>
              ))}
            </List>
            <div className="swiper-pagination" />
          </SliderContainer>
        ) : null}
      </ContentContainer>
    </SectionOverflow>
  ) : null;
}

const SectionOverflow = styled(Section)`
  overflow: hidden;
`;

const Title = styled(SectionTitle)`
  margin-bottom: 40px;
`;

const SectionIntro = styled.p`
  max-width: 500px;
  margin: 0 auto 60px;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.4px;
  text-align: center;
  color: #565656;
  span {
    color: ${colors.green};
  }
`;

const SliderContainer = styled.div`
  .swiper-pagination {
    position: relative;
    bottom: 0;
    margin-top: 30px;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;

      &-active {
        background-color: ${colors.green};
      }
    }

    ${media.tabletUp(css`
      display: none;
    `)}
  }
`;

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  max-width: 875px;
  margin: 0 auto;
`;

const Item = styled.li`
  width: calc(33.333% - 13px);

  &:not(:last-child) {
    margin-right: 20px;
  }

  ${media.mobile(css`
    width: auto;
    padding: 25px;
    border: 1px solid #dadcda;
    border-radius: 5px;
    overflow: hidden;
  `)}
`;

export default BestOfTheMonth;
