import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import ContentContainer from '@/components/ContentContainer';
import Tab from '@/components/__old__/Tab';
import TabContent from '@/components/__old__/TabContent';
import { media } from '@/utils/mixin';
import { convertSlugToPath } from '@/utils/common';
import { useTypedSelector } from '@/store/store';
import { selectPageByPath } from '@/store/reducers/tager/pages';

import { SectionTitle, Section } from './SeoComponents.style';

function WhatIncluded() {
  const router = useRouter();
  const currentPath = convertSlugToPath(router.query.path);
  const pageData = useTypedSelector((state) =>
    selectPageByPath(state, currentPath)
  );

  const [activeTab, setActiveTab] = useState(
    pageData?.templateFields?.servicesTabs[0]
  );

  const templateFields = pageData?.templateFields;

  if (!(templateFields && templateFields?.servicesTabs)) return null;
  const tabContent = templateFields.servicesTabs.find(
    (tabContent: { name: string }) =>
      activeTab ? tabContent.name === activeTab.name : null
  );
  const servicesExtendedButtonLabel =
    templateFields.servicesExtendedButtonLabel;

  return (
    <SectionTabs>
      <ContentContainer>
        <SectionTitle>{templateFields.servicesTitle}</SectionTitle>
      </ContentContainer>
      <SectionContent>
        <ContentContainer>
          <TabList>
            {templateFields.servicesTabs.map((tab: { name: string }) =>
              activeTab ? (
                <Tab
                  key={tab.name}
                  tab={tab}
                  isActive={tab.name === activeTab.name}
                  onChange={setActiveTab}
                />
              ) : null
            )}
          </TabList>
        </ContentContainer>
        {tabContent && (
          <TabContent
            withModal
            {...tabContent}
            buttonLabel={servicesExtendedButtonLabel}
          />
        )}
      </SectionContent>
    </SectionTabs>
  );
}

const TabList = styled.ul`
  display: flex;
  width: 780px;
  margin: 0 auto 60px;

  ${media.tabletSmall(css`
    width: auto;
    max-width: 525px;
    flex-wrap: wrap;
  `)}
`;

const SectionTabs = styled(Section)`
  padding-bottom: 0;

  ${media.tabletSmall(css`
    padding: 50px 0;
  `)}
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export default WhatIncluded;
