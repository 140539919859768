import React from 'react';
import { useRouter } from 'next/router';

import Layout from '@/components/Layout';
import QaSection from '@/components/__old__/QaSection';
import FacebookSection from '@/modules/Templates/Home/components/FacebookSection';
import CostSection from '@/components/__old__/SeoComponents/CostSection';
import useCurrentPage from '@/hooks/useCurrentPage';
import IncludedInService from '@/modules/Templates/Windows/components/IncludedInService';

import Hero from './components/Hero';
import BottomSection from './components/BottomSection';
import HowItWorks from './components/HowItWorks/HowItWorks';

function Windows() {
  const router = useRouter();

  const pageData = useCurrentPage();
  const templateFields = pageData?.templateFields;

  if (!templateFields) return null;

  return (
    <Layout
      config={{
        header: {
          colorVariant: 'green',
          isMainBg: false,
          logoText: 'Уборка квартир',
          isApartment: true,
        },
      }}
    >
      <Hero templateFields={templateFields} />
      {/*<FacebookSection
        facebookFrameUrl={templateFields.facebookFrameUrl}
        facebookButtonLabel={templateFields.facebookButtonLabel}
        facebookButtonLink={templateFields.facebookButtonLink}
      />*/}
      <CostSection
        title={templateFields.costTitle}
        costItems={templateFields.costItems}
      />
      <HowItWorks templateFields={templateFields} />
      {templateFields?.servicesTabs ? (
        <IncludedInService templateFields={templateFields} />
      ) : null}
      {templateFields?.faqItems ? (
        <QaSection
          faqItems={templateFields.faqItems}
          faqTitle={templateFields.faqTitle}
        />
      ) : null}
      <BottomSection templateFields={templateFields} />
    </Layout>
  );
}

export default Windows;
