import React from 'react';
import styled, { css } from 'styled-components';

import useSliderMedia from '@/hooks/useSliderMedia';
import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import BasicListItem from '@/components/__old__/BasicListItem';
import { DEFAULT_SLIDER_CONFIG } from '@/constants/common';

import { Section, SectionTitle } from './SeoComponents.style';

type Props = {
  howToTitle: string;
  howToTabs: Array<{
    content: string;
    image: { url: string };
    title: string;
  }>;
};

function HowToOrder({ howToTitle, howToTabs }: Props) {
  const ref = useSliderMedia('(max-width: 1023px)', DEFAULT_SLIDER_CONFIG);

  const howToTitleArray: Array<string> = howToTitle
    ? howToTitle.split('\n')
    : [];

  return howToTitleArray.length || (howToTabs && howToTabs.length) ? (
    <Section>
      <ContentContainer>
        {howToTitleArray.length ? (
          <SectionTitle>
            {howToTitleArray[0]}
            <br /> {howToTitleArray[1]}
          </SectionTitle>
        ) : null}
        {howToTabs && howToTabs.length ? (
          <SliderContainer className="swiper-container" ref={ref}>
            <List className="swiper-wrapper">
              {howToTabs.map((orderItem, index) => (
                <ListItem key={index} className="swiper-slide">
                  <BasicListItem item={orderItem} />
                </ListItem>
              ))}
            </List>
            <div className="swiper-pagination" />
          </SliderContainer>
        ) : null}
      </ContentContainer>
    </Section>
  ) : null;
}

const SliderContainer = styled.div`
  .swiper-pagination {
    display: none;
    position: relative;
    bottom: 0;
    margin-top: 30px;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;

      &-active {
        background-color: ${colors.green};
      }
    }

    ${media.tabletSmall(css`
      display: block;
    `)}
  }
`;

const List = styled.ul`
  display: flex;
  justify-content: space-between;
`;

const ListItem = styled.li`
  width: 33.3333%;
  padding: 0 20px;

  ${media.tabletSmall(css`
    width: 100%;
    padding: 0;
  `)}
`;

export default HowToOrder;
