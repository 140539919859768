import React from 'react';
import styled, { css } from 'styled-components';
import { Form, Formik, FormikErrors, FormikValues } from 'formik';
import { TFunction } from 'react-i18next';

import { ModalProps } from '@tager/web-components';

import { ReactComponent as CloseIcon } from '@/assets/svg/close-popup.svg';
import {
  ModalCloseButton,
  ModalContainer,
} from '@/components/__old__/Modal.style';
import useSettingItem from '@/hooks/useSettingItem';
import Button from '@/components/Button';
import cleanerImg from '@/assets/images/career/cleaner.png';
import { MaskedTextInputFormik } from '@/components/__old__/MaskedTextInput';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { validators } from '@/utils/validation';
import { useTranslation } from '@/i18n';

type Props = ModalProps<{
  title: string;
  text: string;
  buttonLabel: string;
  onSubmit: (phone: string) => void;
}>;

type FormValues = {
  phone: string;
};

function validate(values: FormValues, t: TFunction<string>) {
  const errors: FormikErrors<FormikValues> = {};

  if (validators.required(values.phone)) {
    errors.phone = t('empty_phone');
  }

  return errors;
}

const OrderModal: React.FC<Props> = ({ closeModal, innerProps }) => {
  const { title, text, buttonLabel, onSubmit } = innerProps;

  const { t } = useTranslation();
  const phoneMask = useSettingItem('LAYOUT_PHONE_MASK');

  return (
    <ModalContainer padding={'0'}>
      <ModalCloseButton onClick={closeModal} variant={'green'}>
        <CloseIcon />
      </ModalCloseButton>
      <Component>
        <Left>
          <img src={cleanerImg} alt={title || ''} />
        </Left>
        <Right>
          <Title>{title}</Title>
          <Text>{text}</Text>
          <Formik<FormValues>
            initialValues={{
              phone: '',
            }}
            validate={(values) => validate(values, t)}
            onSubmit={(values) => {
              onSubmit(values.phone);
              setTimeout(() => {
                closeModal();
              }, 100);
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {(formProps) => (
              <FormRow {...formProps}>
                <InputWrapper>
                  <MaskedTextInputFormik
                    prefix={'+375'}
                    type="tel"
                    name="phone"
                    clearErrorOnChange
                    definitions={{ X: /[0-9]/, Х: /[0-9]/, '0': /0/ }}
                    mask={phoneMask ?? '0'}
                    placeholder={phoneMask ?? ''}
                  />
                </InputWrapper>
                <SubmitButton type="submit" colorVariant="green">
                  {buttonLabel}
                </SubmitButton>
              </FormRow>
            )}
          </Formik>
          <Agreement>
            Нажимая "{buttonLabel}", я даю согласие на{' '}
            <a href="/privacy-policy" target="_blank">
              обработку персональных данных
            </a>
          </Agreement>
        </Right>
      </Component>
    </ModalContainer>
  );
};

const Component = styled.div`
  display: flex;
  padding: 0 20px;
  justify-content: center;
  align-items: flex-end;

  ${media.mobile(css`
    display: block;
  `)}
`;

const SubmitButton = styled(Button)`
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  margin-left: -10px;
  z-index: 1;
  border-radius: 0 5px 5px 0;

  ${media.mobile(css`
    border-radius: 5px;
    padding: 15px;
    width: 100%;
    margin: 10px 0 0 0;
    justify-content: center;
  `)}
`;

const Left = styled.span`
  flex: 1 1 1px;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  ${media.mobile(css`
    padding: 20px;
  `)}

  img {
    max-height: 330px;

    ${media.mobile(css`
      max-height: 200px;
    `)}
  }
`;

const Right = styled.span`
  flex: 1 1 1px;
  padding-bottom: 40px;

  ${media.mobile(css`
    display: block;
    padding-bottom: 20px;
  `)}
`;

const Title = styled.span`
  font-size: 60px;
  color: ${colors.green};
  font-weight: bold;

  ${media.mobile(css`
    text-align: center;
    font-size: 45px;
    display: block;
  `)}
`;

const FormRow = styled(Form)`
  display: flex;
  margin-top: 20px;

  ${media.mobile(css`
    display: block;
  `)}
`;

const InputWrapper = styled.div`
  flex: 1 1 1px;
`;

const Text = styled.p`
  display: block;
  margin-top: 20px;
  font-size: 30px;
  ${media.mobile(css`
    margin-top: 20px;
    font-size: 16px;
  `)}
`;

const Agreement = styled.span`
  display: block;
  margin-top: 20px;
  font-size: 14px;
  line-height: 140%;
  opacity: 0.75;

  ${media.mobile(css`
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  `)}

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

export default OrderModal;
