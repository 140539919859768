import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import { WhatIsIncluded } from '@/modules/Templates/Repair/Repair.types';

interface Props extends WhatIsIncluded {
  className?: string;
}

function CleaningItem({ className, image, title, text }: Props) {
  return (
    <Item className={className}>
      <Photo>
        <Picture
          src={image.url}
          src2x={image.url_2x}
          srcWebp={image.url_webp}
          srcWebp2x={image.url_webp_2x}
        />
      </Photo>
      <ItemTitle>{title}</ItemTitle>
      <ItemText>{text}</ItemText>
    </Item>
  );
}

const Item = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 360px;

  ${media.tabletSmall(css`
    flex: 0 0 320px;
  `)}

  ${media.mobileMedium(css`
    flex: 0 0 100%;
  `)}
`;

const Photo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const ItemTitle = styled.span`
  display: block;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
`;

const ItemText = styled.p`
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  max-width: 100%;
`;

export default CleaningItem;
