import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ReactComponent as CloseIcon } from '@/assets/svg/close-popup.svg';

import { EXCLUDED_ITEM_LIST } from '../AboutCleaning.constants';

type Props = {
  text: string;
};

export function ExcludedItem({ text }: Props) {
  return (
    <Item>
      <CloseIcon />
      {text}
    </Item>
  );
}

function ExcludedList({
  servicesExtendedBottomItems,
}: {
  servicesExtendedBottomItems: string;
}) {
  const excludedItemList = servicesExtendedBottomItems.split('\n');
  return (
    <List>
      {excludedItemList.map((item, index) => (
        <ExcludedItem key={index} text={item} />
      ))}
    </List>
  );
}

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  ${media.mobile(css`
    margin: 0;
  `)}
`;

const Item = styled.li`
  position: relative;
  width: calc(50% - 30px);
  padding: 5px 0 5px 27px;
  margin: 0 15px;
  font-size: 16px;
  line-height: 19px;
  color: #868f9b;

  svg {
    position: absolute;
    top: 9px;
    left: 0;
    width: 12px;
    height: 12px;
    fill: #f86d50;
  }

  ${media.mobile(css`
    width: 100%;
    padding-left: 20px;
    margin: 0;
  `)}
`;

export default ExcludedList;
