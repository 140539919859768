import React from 'react';
import styled, { css } from 'styled-components';

import { Nullish } from '@tager/web-core';
import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import ContentContainer from '@/components/ContentContainer';
import Picture from '@/components/Picture';

import CalculatorForm from './CalculatorForm';

type Props = {
  image: Nullish<ThumbnailType>;
  title: Nullish<string>;
};

function Hero({ image, title }: Props) {
  return (
    <HeroContainer>
      <Background>
        <Picture {...convertThumbnailToPictureProps(image)} />
      </Background>
      <ContentContainer>
        <HeroInner>
          <Intro>
            <Title>{title}</Title>
          </Intro>
          <FormContainer>
            <CalculatorForm />
          </FormContainer>
        </HeroInner>
      </ContentContainer>
    </HeroContainer>
  );
}

const HeroContainer = styled.div`
  position: relative;
  padding-top: 100px;
  padding-bottom: 70px;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  z-index: 0;
  display: flex;
  align-items: center;

  ${media.mobile(css`
    height: auto;
  `)}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(21, 21, 21, 0.15);
  }

  img {
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }
`;

const HeroInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${media.tabletSmall(css`
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
  `)}
`;

const FormContainer = styled.div`
  flex: 0 0 453px;
  margin-left: 50px;

  ${media.laptop(css`
    flex: 0 0 420px;
  `)}

  ${media.tabletSmall(css`
    flex: 0;
    max-width: 420px;
    width: 100%;
    margin: 0 auto;
  `)}
`;

const Intro = styled.div`
  display: inline-flex;

  ${media.laptop(css`
    max-width: 530px;
  `)}

  ${media.tablet(css`
    max-width: 455px;
  `)}

  ${media.tabletSmall(css`
    max-width: none;
    margin-bottom: 20px;
  `)}
`;

const Title = styled.h1`
  margin: 0;
  font-size: 49px;
  font-weight: bold;
  line-height: 1.2;
  padding: 8px;
  background-color: rgba(18, 126, 251, 0.7);
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 40px;
  `)}

  ${media.tablet(css`
    font-size: 34px;
  `)}
  
  ${media.tabletSmall(css`
    margin: 0 auto;
  `)}
  
  ${media.mobile(css`
    font-size: 28px;
  `)}
`;

export default Hero;
