import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { useMedia } from '@tager/web-core';
import { useModal } from '@tager/web-components';
import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { googleAnalytics } from '@tager/web-analytics';

import AuthModal from '@/modules/Modals/AuthModal';
import { media } from '@/utils/mixin';
import { ThemeColor } from '@/typings/common';
import { colors } from '@/constants/theme';
import Button from '@/components/__old__/Button';
import Link from '@/components/Link';
import { ReactComponent as PhoneIcon } from '@/assets/svg/phone-icon.svg';
import { getColorByVariant, getPhoneNumber } from '@/utils/common';
import useSettingItem from '@/hooks/useSettingItem';
import ContentContainer from '@/components/ContentContainer';
import { useTypedSelector } from '@/store/store';
import { selectIsUserAuthorized } from '@/store/reducers/auth';
import ProfileDropdown from '@/components/Layout/components/ProfileDropdown';
import { useTranslation } from '@/i18n';
import Picture from '@/components/Picture';
import HeaderLocation from '@/components/Layout/components/Header/components/HeaderLocation';
import useCurrentPageTemplate from '@/hooks/useCurrentPageTemplate';

import { LayoutConfig } from '../../Layout.types';

import santaImg from './assets/santa.png';

type Props = LayoutConfig['header'];

function Header({
  colorVariant,
  isMainBg,
  isOfficePageInfo,
  isRepairPageInfo,
  disableRegion,
}: Props) {
  const { t } = useTranslation();
  const pageTemplate = useCurrentPageTemplate();

  const [isFixed, setFixed] = useState(false);
  const [isOpened, setOpened] = useState(false);

  const openModal = useModal();
  const isUserAuthorized = useTypedSelector((state) =>
    selectIsUserAuthorized(state)
  );
  const isTablet = useMedia('(max-width: 1260px)');

  const phoneCommon = useSettingItem('LAYOUT_PHONE');
  const phoneOffice = useSettingItem('LAYOUT_OFFICES_PHONE');

  const logoDefault = useSettingItem('LOGO_HEADER');
  const logoOffice = useSettingItem('LOGO_HEADER_OFFICE');
  const logoRepair = useSettingItem('LOGO_HEADER_REPAIR');

  const phone = isOfficePageInfo ? phoneOffice : phoneCommon;
  const phoneNumber = getPhoneNumber(phone ?? '');

  useEffect(() => {
    if (!isTablet && isOpened) {
      setOpened(false);
    }
  }, [isTablet, isOpened]);

  useEffect(() => {
    function listener(this: Document, event: Event) {
      const INITIAL_OFFSET = 40;

      if (INITIAL_OFFSET < window.pageYOffset && !isFixed) {
        setFixed(true);
      }

      if (INITIAL_OFFSET >= window.pageYOffset && isFixed) {
        setFixed(false);
      }
    }

    document.addEventListener('scroll', listener);

    return () => document.removeEventListener('scroll', listener);
  }, [isFixed]);

  const router = useRouter();

  function handleLogin() {
    if (!isUserAuthorized) {
      openModal(AuthModal, {});
    }
  }

  const withNewYear = useSettingItem('LAYOUT_NEW_YEAR');

  /** TODO: refactor links logic depend on page template! */
  return (
    <HeaderContainer isFixed={isFixed} isMainBg={isMainBg}>
      <HeaderContent isFixed={isFixed}>
        <ContentContainer isUserAuthorized={isUserAuthorized}>
          <HeaderInner isFixed={isFixed} isMainBg={isMainBg}>
            <HeaderLeft>
              <LogoContainer>
                <Link to="/">
                  {(linkProps) => (
                    <LogoLink
                      variant={colorVariant}
                      isFixed={isFixed}
                      isMainBg={isMainBg}
                      withNewYear={!!withNewYear}
                      {...linkProps}
                    >
                      {withNewYear ? (
                        <SantaImage>
                          <img src={santaImg} />
                        </SantaImage>
                      ) : null}
                      {logoDefault ? (
                        <Logo
                          isShow={!isRepairPageInfo && !isOfficePageInfo}
                          {...convertThumbnailToPictureImage(logoDefault)}
                        />
                      ) : null}
                      {logoOffice ? (
                        <Logo
                          isShow={!!isOfficePageInfo}
                          {...convertThumbnailToPictureImage(logoOffice)}
                        />
                      ) : null}
                      {logoRepair ? (
                        <Logo
                          isShow={!!isRepairPageInfo}
                          {...convertThumbnailToPictureImage(logoRepair)}
                        />
                      ) : null}
                    </LogoLink>
                  )}
                </Link>

                {pageTemplate === 'offices' ? (
                  <ButtonBusiness>{t('header_for_business')}</ButtonBusiness>
                ) : null}

                {disableRegion ? null : <HeaderLocation mode="MOBILE" />}
              </LogoContainer>
            </HeaderLeft>

            <LeftHeaderPhoneLink
              href={'tel:' + phoneNumber}
              variant={colorVariant}
              isUserAuthorized={isUserAuthorized}
              onClick={() => {
                googleAnalytics.trackEvent('event', 'phone');
              }}
            >
              {phone}
            </LeftHeaderPhoneLink>

            <HeaderRight>
              <RightItem>
                {phone ? (
                  <RightHeaderPhoneLink
                    href={'tel:' + phoneNumber}
                    variant={colorVariant}
                    onClick={() => {
                      googleAnalytics.trackEvent('event', 'phone');
                    }}
                  >
                    <PhoneIcon />
                    {phone}
                  </RightHeaderPhoneLink>
                ) : null}
              </RightItem>
              <RightItem>
                {isUserAuthorized ? (
                  <ProfileDropdown colorVariant={colorVariant} />
                ) : (
                  <HeaderButton
                    variant={colorVariant}
                    onClick={handleLogin}
                    icon={'user'}
                  >
                    Войти
                  </HeaderButton>
                )}
              </RightItem>
            </HeaderRight>
          </HeaderInner>
        </ContentContainer>
      </HeaderContent>
    </HeaderContainer>
  );
}

export type ColorVariant = ThemeColor;

const HeaderContainer = styled.header<{ isFixed: boolean; isMainBg: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  transition: 1s;
  position: fixed;

  ${(props) =>
    props.isFixed
      ? css`
          background-color: ${colors.white};
          box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
        `
      : !props.isFixed && props.isMainBg
      ? css`
          background-color: ${colors.white};
        `
      : css`
          background-color: ${colors.white};
        `};

  ${media.tablet(css`
    background-color: ${colors.white};
  `)}
`;

const HeaderContent = styled.div<{ isFixed: boolean }>`
  width: 100%;
  max-height: 64px;
  box-shadow: 0 3px 15px -9px #808080;
  position: relative;
  z-index: 10;
  transition: 6s;
  ${(props) =>
    props.isFixed
      ? css`
          transition: 0.2s;
          background-color: ${colors.white};
        `
      : null}
`;
const HeaderInner = styled.div<{ isFixed: boolean; isMainBg: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 1s;
  ${(props) =>
    props.isFixed
      ? css`
          padding: 11px 0;
        `
      : props.isMainBg
      ? css`
          padding: 11px 0;
          ${media.tablet(css``)}
        `
      : css`
          padding: 11px 0;
        `}
`;

const HeaderLeft = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${media.mobile(css`
    display: flex;
  `)};
`;

const ButtonBusiness = styled.span`
  background-color: #2086f6;
  font-weight: 500;
  border-radius: 7px;
  padding: 6px 7px;
  margin-left: 15px;
  color: ${colors.white};

  ${media.tabletSmall(css`
    font-size: 11px;
  `)}

  ${media.mobile(css`
    display: none;
  `)}
`;

const LogoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${media.tabletSmall(css`
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      width: 120px;
      height: auto;
    }
  `)}

  ${media.mobile(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

const SantaImage = styled.div`
  position: absolute;
  right: -30px;
  top: -20px;
  img {
    width: 48px;
    height: 48px;
  }

  ${media.mobile(css`
    right: -20px;
    top: -12px;
    img {
      width: 32px;
      height: 32px;
    }
  `)}
`;

const LogoLink = styled.a<{
  variant: ColorVariant;
  isFixed: boolean;
  isMainBg: boolean;
  withNewYear: boolean;
}>`
  position: relative;
  display: block;
  color: ${(props) => getColorByVariant(props.variant)};

  ${(props) =>
    props.withNewYear &&
    css`
      margin-right: 15px;
    `}

  ${media.mobile(css`
    svg {
      width: 100px;
      //width: 125px;
      height: auto;
    }
  `)}
  ${media.mobileSmall(css`
    svg {
      width: 90px;
    }
  `)}
`;

const Logo = styled(Picture)<{ isShow: boolean }>`
  display: ${(props) => (props.isShow ? 'inline-block' : 'none')};

  img {
    max-width: 147px;

    @media (max-width: 425px) {
      max-width: 94px;
    }
  }
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const RightItem = styled.div<{ hiddenMobile?: boolean }>`
  &:not(:last-child) {
    margin-right: 25px;
  }

  ${media.mobile(css`
    margin-right: 0;
  `)}

  ${(props) =>
    props.hiddenMobile
      ? media.mobile(css`
          display: none;
        `)
      : null}
`;

const headerPhoneLinkCss = css`
  position: relative;
  padding-left: 20px;
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  ${media.mobile(css`
    font-size: 14px;
    padding-left: 0;
  `)}
  svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    fill: currentColor;
    ${media.mobile(css`
      display: none;
    `)}
  }

  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`;

const LeftHeaderPhoneLink = styled.a<{
  variant: ColorVariant;
  isUserAuthorized?: boolean;
}>`
  ${headerPhoneLinkCss};
  display: none;
  color: ${colors.grayLight};
  // color: ${(props) => getColorByVariant(props.variant)};
  ${media.mobile(css`
    line-height: 26px;
    display: inline-block;
  `)}
  ${(props) =>
    props.isUserAuthorized
      ? css`
          ${media.mobile(css`
            margin-right: 43px;
          `)}
          ${media.mobileSmall(css`
            margin-right: 14px;
          `)}
        `
      : null}
  ${media.mobileSmall(css`
    font-size: 12px;
  `)}
`;
const RightHeaderPhoneLink = styled.a<{ variant: ColorVariant }>`
  ${headerPhoneLinkCss};

  color: ${(props) => getColorByVariant(props.variant)};
  ${media.mobile(css`
    display: none;
  `)}
`;

const buttonCss = css`
  padding: 9px 11px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  cursor: pointer;

  svg {
    margin-top: 2px;
  }

  ${media.mobile(css`
    margin-right: 0;
  `)}
  ${media.mobileSmall(css`
    font-size: 14px;
  `)}
`;

const HeaderButton = styled(Button)<{ variant: ColorVariant }>`
  ${buttonCss};
  border-width: 1px;
  border-style: solid;
`;

export default Header;
