import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { ReactComponent as CheckIcon } from '@/assets/svg/check.svg';
import { ReactComponent as LockIcon } from '@/assets/svg/lock.svg';

import { Section, SectionTitle } from '../Office.style';
import { OFFICE_CLEANER_URLS, STEP_ITEMS_LIST } from '../Office.constants';

function HowToStart() {
  return (
    <Section>
      <ContentContainer>
        <SectionTitle>Как начать работать</SectionTitle>
        <SectionContent>
          <Left>
            <LeftPhoto>
              <Picture
                srcWebp={OFFICE_CLEANER_URLS.srcWebp}
                srcWebp2x={OFFICE_CLEANER_URLS.srcWebp2x}
                src={OFFICE_CLEANER_URLS.src}
                src2x={OFFICE_CLEANER_URLS.src2x}
              />
            </LeftPhoto>
          </Left>
          <Right>
            <List>
              {STEP_ITEMS_LIST.map((item, index) => (
                <ListItem key={index}>
                  <ItemTitle>{item.title}</ItemTitle>
                  {item.list.map((text, index) => (
                    <StepItem key={index}>
                      <CheckIcon />
                      {text}
                    </StepItem>
                  ))}
                </ListItem>
              ))}
              <ListItem>
                <ItemTitleIcon>
                  <LockIcon />
                  Страхуем ваше имущество
                </ItemTitleIcon>
                Мы сформировали внутренний фонд. Если что-то пойдет не так - мы
                возместим ущерб имуществу вашей компании.
              </ListItem>
            </List>
          </Right>
        </SectionContent>
      </ContentContainer>
    </Section>
  );
}

const SectionContent = styled.div`
  display: flex;
  border-radius: 10px;
  background-color: ${colors.gray};
  box-shadow: 3.214px 3.83px 34px 6px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  ${media.tabletSmall(css`
    flex-wrap: wrap;
  `)}
`;

const Left = styled.div`
  position: relative;
  width: 50%;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-top: 50%;
  }

  ${media.tabletSmall(css`
    width: 100%;
    order: 1;
  `)}
`;

const LeftPhoto = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: 75%;
    object-fit: cover;

    ${media.mobile(css`
      height: 100%;
    `)}
  }
`;

const Right = styled.div`
  width: 50%;
  padding: 40px 100px 35px 35px;
  background-color: ${colors.white};

  ${media.tablet(css`
    padding: 30px 60px 30px 30px;
  `)}

  ${media.tabletSmall(css`
    width: 100%;
    padding: 30px;
  `)}
`;

const List = styled.ul``;

const ListItem = styled.li`
  font-size: 22px;
  line-height: 1.2;
  color: #2a2a2a;

  &:not(:last-child) {
    margin-bottom: 50px;
  }

  ${media.laptop(css`
    font-size: 20px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
  `)}
  
  ${media.tabletSmall(css`
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
  `)}
`;
const ItemTitleCss = css`
  display: block;
  margin-bottom: 10px;
  font-size: 25px;
  line-height: 1.2;
  color: #282828;
  font-weight: 600;
  text-transform: uppercase;

  ${media.laptop(css`
    font-size: 22px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
  `)}
  
  ${media.mobile(css`
    font-size: 18px;
  `)}
`;

const ItemTitle = styled.span`
  ${ItemTitleCss}
`;

const ItemTitleIcon = styled.span`
  ${ItemTitleCss};
  position: relative;
  padding-left: 32px;

  svg {
    fill: ${colors.blue};
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const StepItem = styled.span`
  position: relative;
  display: block;
  padding-left: 25px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  svg {
    position: absolute;
    left: 0;
    top: 10px;
  }

  ${media.mobile(css`
    svg {
      top: 5px;
    }
  `)}
`;

export default HowToStart;
