import React from 'react';
import styled from 'styled-components';

import Layout from '@/components/Layout';
import SuccessPageProvider from '@/components/__old__/SuccessPageProvider';
import OrderSuccess from '@/modules/Templates/Repair/components/Order/Order';
import useCurrentPage from '@/hooks/useCurrentPage';

import Hero from './components/Hero';
import ServicesSection from './components/ServicesSection';
import TypesOfCleaning from './components/TypesOfCleaning';
import HowToStart from './components/HowToStart';
import Advantages from './components/Advantages';
import BottomSection from './components/BottomSection';
import TrustUs from './components/TrustUs';

function Office() {
  const page = useCurrentPage();

  return (
    <Layout
      config={{
        header: {
          colorVariant: 'blue',
          logoText: 'Уборка офисов',
          isMainBg: false,
          isOfficePageInfo: true,
        },
        footer: { isOfficePageInfo: false },
      }}
    >
      <SuccessPageProvider>
        {(isSuccess) =>
          isSuccess ? (
            <OrderSuccess />
          ) : (
            <Wrapper>
              <Hero image={page?.image} title={page?.title} />
              <ServicesSection />
              <TypesOfCleaning />
              <HowToStart />
              <Advantages />
              <BottomSection />
            </Wrapper>
          )
        }
      </SuccessPageProvider>
    </Layout>
  );
}

const Wrapper = styled.div``;

export default Office;
