import { facebookPixel, gtag } from '@tager/web-analytics';

export function facebookPixelTrack(event: string) {
  console.log('Facebook Pixel: ', event);

  const facebookTracker = facebookPixel.getTracker();
  if (facebookTracker) {
    facebookTracker('track', event);
  }
}

export function analyticsOrderStartTriggerEvent() {
  gtag.event('order:start');
  facebookPixelTrack('Lead');
}
