import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import ContentContainer from '@/components/ContentContainer';
import Picture from '@/components/Picture';
import { useRepairData } from '@/modules/Templates/Repair/Repair.hooks';

import CalculatorForm from './CalculatorForm';

function Hero() {
  const { heroTitle, heroSubtitle, heroBackground } = useRepairData();

  if (!heroTitle || !heroSubtitle || !heroBackground) {
    return null;
  }

  return (
    <HeroContainer>
      <Background>
        <Picture
          src={heroBackground?.url}
          src2x={heroBackground?.url_2x}
          srcWebp={heroBackground?.url_webp}
          srcWebp2x={heroBackground?.url_webp_2x}
        />
      </Background>
      <ContentContainer>
        <HeroInner>
          <Intro>
            <Title dangerouslySetInnerHTML={{ __html: heroTitle ?? '' }} />
            <Info>
              <Subtitle>{heroSubtitle}</Subtitle>
            </Info>
          </Intro>
          <FormContainer>
            <CalculatorForm />
          </FormContainer>
        </HeroInner>
      </ContentContainer>
    </HeroContainer>
  );
}

const HeroContainer = styled.div`
  position: relative;
  padding-top: 100px;
  padding-bottom: 70px;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  z-index: 0;
  display: flex;
  align-items: center;

  ${media.tabletLarge(css`
    padding-top: 95px;
    padding-bottom: 80px;
    height: auto;
  `)}
  ${media.mobile(css`
    height: auto;
    padding-top: 70px;
  `)}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }
`;

const HeroInner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tabletSmall(css`
    justify-content: center;
    align-items: normal;
    flex-direction: column;
  `)}
`;

const FormContainer = styled.div`
  flex: 0 0 470px;
  margin-left: 50px;

  ${media.laptop(css`
    flex: 0 0 420px;
  `)}

  ${media.tabletSmall(css`
    flex: 0;
    max-width: 420px;
    width: 100%;
    margin: 0 auto;
  `)}
`;

const Intro = styled.div`
  max-width: 600px;

  ${media.tabletSmall(css`
    max-width: 100%;
    margin-bottom: 30px;
    text-align: center;
  `)}

  ${media.mobile(css`
    max-width: 100%;
    margin-bottom: 10px;
    text-align: center;
  `)}
`;

const Title = styled.h1`
  margin-bottom: 20px;
  margin-top: 0;
  font-size: 80px;
  font-weight: 800;
  line-height: 1.2;
  color: ${colors.darkText};

  strong {
    color: ${colors.yellowDark};
  }

  ${media.laptop(css`
    font-size: 72px;
  `)}

  ${media.tablet(css`
    font-size: 60px;
  `)}

  ${media.mobile(css`
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 10px;
  `)}
`;

const Info = styled.div`
  display: flex;
  width: 67%;
  justify-content: center;
  margin: 0;

  ${media.tabletSmall(css`
    width: 100%;
  `)}
`;

const Subtitle = styled.p`
  display: inline-flex;
  padding: 6px;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  color: ${colors.white};
  background-color: rgba(18, 126, 251, 0.7);

  ${media.mobile(css`
    font-size: 18px;
  `)}
`;

export default Hero;
