import React from 'react';
import styled from 'styled-components';
import { FieldConfig, useField } from 'formik';

import { colors } from '@/constants/theme';
import { ReactComponent as MinusIcon } from '@/assets/svg/counter/minus.svg';
import { ReactComponent as PlusIcon } from '@/assets/svg/counter/plus.svg';

type CounterProps = {
  getLabel: (count: number) => React.ReactNode;
  value: number;
  onChange: (count: number) => void;
  max?: number;
  min?: number;
};

type CounterFormikProps = Omit<CounterProps, 'value' | 'onChange'> &
  Pick<FieldConfig, 'name'>;

function CounterFormik({ name, ...rest }: CounterFormikProps) {
  const [field, meta, helpers] = useField<number>({ name });

  return (
    <Counter
      {...rest}
      value={field.value}
      onChange={(count) => helpers.setValue(count)}
    />
  );
}

function Counter({ getLabel, value, onChange, max, min = 1 }: CounterProps) {
  return (
    <Container>
      <DecrementButton
        type="button"
        onClick={() => onChange(value - 1)}
        disabled={value === min}
      >
        <MinusIcon />
      </DecrementButton>
      <LabelText>{getLabel(value)}</LabelText>
      <IncrementButton
        type="button"
        onClick={() => onChange(value + 1)}
        disabled={value === max}
      >
        <PlusIcon />
      </IncrementButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;

const LabelText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 1.2;
  background-color: transparent;
  flex: 1 1 auto;
`;

const CounterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 2px solid ${colors.border};
  background-color: ${colors.white};
  overflow: hidden;
  cursor: pointer;
  color: ${colors.darkText};

  &:hover {
    color: ${colors.yellowDark};
  }

  &:disabled {
    cursor: default;
    color: ${colors.blackText};
    opacity: 0.75;
  }
`;

const DecrementButton = styled(CounterButton)`
  border-radius: 8px 0 0 8px;
`;

const IncrementButton = styled(CounterButton)`
  border-radius: 0 8px 8px 0;
`;

export default CounterFormik;
