import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import ContentContainer from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';

type Props = {
  className?: string;
  companyList: Array<{ image: { url: string }; url: string }>;
};

function Companies({ className, companyList }: Props) {
  return companyList && companyList.length ? (
    <Section className={className}>
      <ContentContainer>
        <List data-list>
          {companyList.map((company, index) => (
            <Item key={index} data-item>
              {/* <ItemLink
                href={company.url}
                target="_blank"
                rel="noopener noreferrer"
              >*/}
              <Picture {...convertThumbnailToPictureImage(company.image)} />
              {/*</ItemLink>*/}
            </Item>
          ))}
        </List>
      </ContentContainer>
    </Section>
  ) : null;
}

const Section = styled.section`
  padding: 30px 0;
  background-color: #eaebec;

  ${media.laptop(css`
    padding: 25px 0;
  `)}

  ${media.tabletSmall(css`
    padding: 15px 0;
  `)}
`;

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  max-width: 880px;
  margin: -20px auto;

  ${media.tabletSmall(css`
    max-width: 100%;
    margin: -10px;
  `)}

  ${media.mobile(css`
    flex-wrap: wrap;
  `)}
`;

const Item = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;

  filter: grayscale(1);
  opacity: 0.7;

  ${media.mobile(css`
    width: calc(50% - 20px);
    margin: 10px;

    img {
      max-height: 34px;
    }
  `)}
`;

const ItemLink = styled.a`
  display: block;
  filter: grayscale(1);
  opacity: 0.7;

  &:hover {
    filter: grayscale(0);
    opacity: 1;
  }
`;

export default Companies;
