import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { visuallyHidden } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { ReactComponent as CheckIcon } from '@/assets/svg/check.svg';
import { colors } from '@/constants/theme';

type ReservedCheckboxVariant = 'green';

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  className?: string;
  checked?: boolean;
  label?: string | React.ReactElement;
  variant?: ReservedCheckboxVariant;
};

function Checkbox({
  checked: checkedProp,
  className,
  label,
  onChange,
  readOnly,
  id,
  defaultChecked,
  onFocus,
  onBlur,
  variant,
  disabled,
  ...inputProps
}: Props) {
  const { current: isControlled } = React.useRef(
    typeof checkedProp === 'boolean'
  );
  const [checkedState, setCheckedState] = useState(defaultChecked);

  const handleFocus = useCallback(
    (event) => {
      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (event) => {
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );

  const handleChange = useCallback(
    (event) => {
      const checked = event.target.checked;
      if (!isControlled) {
        setCheckedState(checked);
      }

      if (onChange) {
        onChange(event);
      }
    },
    [isControlled, onChange]
  );

  const checked = isControlled ? checkedProp : checkedState;
  return (
    <Label
      className={className}
      hasText={Boolean(label)}
      htmlFor={id}
      aria-label={String(label)}
      isDisabled={disabled}
    >
      <Input
        id={id}
        checked={Boolean(checked)}
        onChange={handleChange}
        defaultChecked={defaultChecked}
        {...inputProps}
        disabled={disabled}
        type="checkbox"
        readOnly={readOnly || !onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <CheckboxBlock variant={variant}>
        {checked ? (
          <Checked variant={variant}>
            <CheckIcon />
          </Checked>
        ) : (
          <Unchecked variant={variant} isDisabled={disabled} />
        )}
      </CheckboxBlock>
      {React.isValidElement(label) ? label : <LabelText>{label}</LabelText>}
    </Label>
  );
}

const Label = styled.label<{
  hasText: boolean;
  isDisabled?: boolean;
}>`
  margin-bottom: 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  line-height: 25px;
  user-select: none;
  cursor: pointer;

  font-size: 18px;

  ${media.mobile(css`
    font-size: 16px;
  `)}

  ${(props) =>
    props.hasText
      ? css`
          padding-right: 2px;
        `
      : null}

  ${(props) =>
    props.isDisabled
      ? css`
          cursor: default;
          color: ${colors.grayDark};
        `
      : null};
`;

const LabelText = styled.span`
  display: inline-block;
`;

const CheckboxBlock = styled.div<{
  widthCheckboxBlock?: number;
  heightCheckboxBlock?: number;
  variant?: ReservedCheckboxVariant;
}>`
  width: 25px;
  height: 25px;
  position: relative;
  margin-right: 20px;
  ${(props) =>
    typeof props.variant === 'string'
      ? props.variant === 'green'
        ? css`
            margin-right: 10px;
          `
        : null
      : props.variant}
`;

const Checked = styled.div<{
  variant?: ReservedCheckboxVariant;
}>`
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: ${colors.yellowDark};
  border-radius: 5px;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: ${colors.black};
  }
  ${(props) =>
    typeof props.variant === 'string'
      ? props.variant === 'green'
        ? css`
            background-color: ${colors.green};
            border: 2px solid ${colors.green};
            svg {
              fill: ${colors.white};
            }
          `
        : null
      : props.variant};
`;

const Unchecked = styled.div<{
  variant?: ReservedCheckboxVariant;
  isDisabled?: boolean;
}>`
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: rgb(248, 250, 235);
  border-radius: 5px;
  ${(props) =>
    typeof props.variant === 'string'
      ? props.variant === 'green'
        ? css`
            background-color: ${colors.white};
            border: 2px solid ${colors.green};
          `
        : null
      : props.variant};

  ${(props) =>
    props.isDisabled
      ? css`
          background-color: #99999940;
        `
      : null};
`;

const Input = styled.input`
  ${visuallyHidden}
`;

export default Checkbox;
