import React from 'react';
import styled from 'styled-components';

import QaAccordion from '@/components/__old__/QaAccordion';

type Props = {
  faqItems: Array<{ answer: string; question: string }>;
};

function AccordsBlock({ faqItems }: Props) {
  return (
    <Accords>
      {faqItems.map((accordItem, index) => (
        <QaAccordion key={index} accordItem={accordItem} />
      ))}
    </Accords>
  );
}

const Accords = styled.div`
  border-top: 1px solid #dadcda;
`;

export default AccordsBlock;
