import React from 'react';
import styled from 'styled-components';

import Layout from '@/components/Layout';
import DefaultHero from '@/components/__old__/DefaultHero';
import useCurrentPage from '@/hooks/useCurrentPage';
import ContentContainer from '@/components/ContentContainer';
import StaticBlock from '@/components/__old__/StaticBlock';
import HowToOrder from '@/components/__old__/SeoComponents/HowToOrder';
import { HOWTO_ORDER_ITEMS } from '@/components/__old__/SeoComponents/HowToOrder.helpers';
import LandingCalculateForm from '@/components/LandingCalculateForm';

function ServiceHelpContent() {
  const page = useCurrentPage();

  return (
    <Layout
      config={{
        header: {
          colorVariant: 'green',
          isMainBg: false,
        },
      }}
    >
      <DefaultHero title={page?.title} image={page?.image} />
      <SectionDefault>
        <HowToOrder
          howToTitle={'Очень хочу заказать!\n Что делать?'}
          howToTabs={HOWTO_ORDER_ITEMS}
        />

        <LandingCalculateForm />

        <ContentContainer>
          <StaticBlock content={page?.body} />
        </ContentContainer>
      </SectionDefault>
    </Layout>
  );
}

const SectionDefault = styled.section`
  padding: 60px 0;
`;

export default ServiceHelpContent;
