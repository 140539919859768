import { Nullish } from '@tager/web-core';

import { PageModuleType } from '@/typings/common';
import {
  getRoomAndBathroomInfoListThunk,
  getWindowAndBalconyInfoListThunk,
} from '@/store/reducers/prices';
import DefaultTemplate from '@/modules/Templates/DefaultTemplate';
import Home from '@/modules/Templates/Home';
import Office from '@/modules/Templates/Office';
import Repair from '@/modules/Templates/Repair';
import Windows from '@/modules/Templates/Windows';
import Career from '@/modules/Templates/Career';
import ServiceList from '@/modules/Templates/ServiceList';
import ServiceHelpContent from '@/modules/Templates/ServiceHelpContent';
import ServiceTabsHelpContent from '@/modules/Templates/ServiceTabsHelpContent';
import ServiceTabsContentHelp from '@/modules/Templates/ServiceTabsContentHelp';
import Subscription from '@/modules/Templates/Subscription';

const DEFAULT_PAGE_MODULE: PageModuleType = {
  component: DefaultTemplate,
  template: '',
};

const PAGE_MODULE_LIST: Array<PageModuleType> = [
  {
    template: 'home',
    component: Home,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(getRoomAndBathroomInfoListThunk()),
        store.dispatch(getWindowAndBalconyInfoListThunk()),
      ]);
    },
  },
  { template: 'offices', component: Office },
  { template: 'after-repair', component: Repair },
  {
    template: 'windows',
    component: Windows,
    getInitialProps({ store }) {
      return Promise.all([store.dispatch(getWindowAndBalconyInfoListThunk())]);
    },
  },
  { template: 'careers', component: Career },
  { template: 'service-list', component: ServiceList },
  { template: 'service-single-help-content', component: ServiceHelpContent },
  {
    template: 'service-single-tabs-help-content',
    component: ServiceTabsHelpContent,
  },
  {
    template: 'service-single-tabs-content-help',
    component: ServiceTabsContentHelp,
  },
  {
    template: 'subscription',
    component: Subscription,
  },
];

export function getPageModuleByTemplate(
  template: Nullish<string>
): PageModuleType {
  return (
    PAGE_MODULE_LIST.find((pageModule) => pageModule.template === template) ??
    DEFAULT_PAGE_MODULE
  );
}
