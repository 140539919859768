import React from 'react';
import styled, { css } from 'styled-components';

import CalculatorForm from '@/modules/Templates/Home/components/CalculatorFormBottomSection';
import { media } from '@/utils/mixin';

function LandingCalculateForm() {
  return (
    <FormWrapper>
      <FormContainer>
        <CalculatorForm submitLabel="Рассчитать стоимость" />
      </FormContainer>
    </FormWrapper>
  );
}

const FormContainer = styled.div`
  width: 296px;

  ${media.tabletSmall(css`
    margin: 0 auto;
  `)}
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 80px;
`;

export default LandingCalculateForm;
