import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

import { PackageCard } from './TabContentCard.types';

function TabContentCard({ title, price }: PackageCard) {
  return (
    <Component>
      <Title>{title}</Title>
      <Price dangerouslySetInnerHTML={{ __html: price ?? '' }} />
    </Component>
  );
}

export default TabContentCard;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 51px 0 56px;
  border-radius: 8px;
  background: ${colors.white};
  transition: 0.3s;
  border: solid 1px rgba(204, 204, 204, 0.3);
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    transition: 0.3s;
  }
`;

const Title = styled.span`
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
`;

const Price = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 11px;
  color: ${colors.green};

  strong {
    font-weight: 600;
    font-size: 25px;
  }
`;
