import React from 'react';
import styled, { css } from 'styled-components';
import {
  Formik,
  FormikProps,
  Form,
  FormikHelpers,
  FormikValues,
  FormikErrors,
} from 'formik';
import { useRouter } from 'next/router';

import { convertRequestErrorToMap } from '@tager/web-core';
import { googleAnalytics, gtag } from '@tager/web-analytics';

import { media } from '@/utils/mixin';
import { validators } from '@/utils/validation';
import {
  CleaningAfterRepairCostRequestBody,
  requestCleaningAfterRepairCost,
} from '@/services/requests/requests';
import { colors } from '@/constants/theme';
import Button from '@/components/__old__/Button';
import { TextInputFormik } from '@/components/__old__/TextInput';
import { CheckboxFormik } from '@/components/__old__/Checkbox';
import { useSuccessPage } from '@/components/__old__/SuccessPageProvider';
import { MaskedTextInputFormik } from '@/components/__old__/MaskedTextInput';
import Spinner, { Overlay } from '@/components/Spinner';
import { useRepairData } from '@/modules/Templates/Repair/Repair.hooks';
import { facebookPixelTrack } from '@/utils/analytics';

import Counter from './Counter';

function getRoomsLabelRussian(count: number): string {
  let word = 'комнат';

  if (count === 1) {
    word = 'комната';
  }

  if (count > 1 && count < 5) {
    word = 'комнаты';
  }

  return `${count} ${word}`;
}

function getBathroomsLabelRussian(count: number): string {
  let word = 'санузлов';

  if (count === 1) {
    word = 'санузел';
  }

  if (count > 1 && count < 5) {
    word = 'санузла';
  }

  return `${count} ${word}`;
}

type FormValues = CleaningAfterRepairCostRequestBody;

function validate(values: FormValues) {
  const errors: FormikErrors<FormikValues> = {};

  if (validators.required(values.name)) {
    errors.name = 'Введите имя';
  }

  if (validators.required(values.phone)) {
    errors.phone = 'Введите номер телефона';
  }

  return errors;
}

function CalculatorFormContainer() {
  const showSuccessPage = useSuccessPage();
  const { formAddress } = useRepairData();

  function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) {
    facebookPixelTrack('Lead');
    gtag.event('repair:start');

    requestCleaningAfterRepairCost(values, formAddress)
      .then((response) => {
        if (response.success) {
          showSuccessPage();
        }
      })
      .catch((error) => {
        helpers.setErrors(convertRequestErrorToMap(error));
        helpers.setSubmitting(false);
      });
  }

  return (
    <Formik<FormValues>
      component={CalculatorForm}
      initialValues={{
        name: '',
        phone: '',
        rooms: 1,
        bathrooms: 1,
        isFurnitureCleaning: false,
        isWindows: false,
        withSteam: false,
        withVacuum: false,
      }}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    />
  );
}

function CalculatorForm({ isSubmitting }: FormikProps<FormValues>) {
  const { formType } = useRepairData();

  return (
    <FormContainer data-testid="cost-request-form">
      {isSubmitting && (
        <Overlay>
          <Spinner color="yellow" />
        </Overlay>
      )}
      <Title>Опишите свою квартиру</Title>
      <NameInputContainer>
        <TextInputFormik
          name="name"
          placeholder="Ваше имя"
          clearErrorOnChange
          data-testid="name-field"
        />
      </NameInputContainer>
      <InputContainer>
        <MaskedTextInputFormik
          prefix={'+375'}
          name="phone"
          placeholder="(XX) XXX-XX-XX"
          mask="(00) 000-00-00"
          clearErrorOnChange
          data-testid="phone-field"
          type="tel"
        />
      </InputContainer>

      <CalcBlockWrapper>
        <CounterRow>
          <Counter name="rooms" getLabel={getRoomsLabelRussian} max={5} />
        </CounterRow>
        <CounterRow>
          <Counter
            name="bathrooms"
            getLabel={getBathroomsLabelRussian}
            max={5}
          />
        </CounterRow>
      </CalcBlockWrapper>

      {formType === 'repair' && (
        <CheckboxContainer>
          <CheckboxFormik
            name="isFurnitureCleaning"
            label="Нужна химчистка мебели"
            data-testid="dry-cleaning-service-field"
          />
        </CheckboxContainer>
      )}

      <CheckboxContainer>
        <CheckboxFormik name="isWindows" label="Нужно вымыть окна" />
      </CheckboxContainer>

      {formType === 'deep' ? (
        <>
          <CheckboxContainer>
            <CheckboxFormik name="withVacuum" label="Нужен пылесос" />
          </CheckboxContainer>

          <CheckboxContainer>
            <CheckboxFormik name="withSteam" label="Нужен пароочиститель" />
          </CheckboxContainer>
        </>
      ) : null}

      <FormButton type="submit" variant={'yellow'} disabled={isSubmitting}>
        Рассчитать стоимость
      </FormButton>
      <Agreement>
        Нажимая "Рассчитать стоимость", я даю согласие на{' '}
        <a href="/privacy-policy" target="_blank">
          обработку персональных данных
        </a>
      </Agreement>
    </FormContainer>
  );
}

const Agreement = styled.span`
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin: 20px -20px 0;
  opacity: 0.75;

  ${media.tablet(css`
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
  `)}
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

const FormContainer = styled(Form)`
  position: relative;
  padding: 30px 60px;
  background-color: ${colors.grayOpacity};
  color: ${colors.darkText};
  border-radius: 10px;
  overflow: hidden;

  ${media.laptop(css`
    padding: 25px 50px;
  `)}

  ${media.mobile(css`
    padding: 10px 25px;
  `)}
`;

const CalcBlockWrapper = styled.div`
  margin-bottom: 20px;
`;

const CounterRow = styled.div`
  margin-bottom: 20px;

  ${media.laptop(css`
    margin-bottom: 15px;
  `)}
  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.span`
  display: block;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;

  ${media.laptop(css`
    font-size: 20px;
  `)}

  ${media.mobile(css`
    margin-bottom: 15px;
  `)}
`;

const FormButton = styled(Button)`
  font-size: 20px;
  padding: 22px 30px;
  width: 100%;
  font-weight: 600;
  color: ${colors.blueDark};

  ${media.laptop(css`
    font-size: 18px;
  `)}

  ${media.mobile(css`
    padding: 22px;
  `)}
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const NameInputContainer = styled(InputContainer)`
  input {
    text-align: center;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  padding-left: 10px;
  margin-bottom: 20px;
  color: ${colors.blueDark};
`;

export default CalculatorFormContainer;
