import React, { useEffect } from 'react';

import { ModalProps } from '@tager/web-components';

import { ModalContainer } from '@/components/__old__/Modal.style';
import { ReactComponent as CloseIcon } from '@/assets/svg/close-icon.svg';
import {
  PreorderData,
  resetPreorderData,
  setPreorderData,
} from '@/store/reducers/auth';
import { useTypedDispatch } from '@/store/store';

import * as S from './AuthModal.style';
import LoginForm from './components/LoginForm';

type Props = ModalProps<{
  phone?: string;
  preorderData?: PreorderData;
}>;

function AuthModal({ closeModal, innerProps: { phone, preorderData } }: Props) {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(resetPreorderData());

    if (preorderData?.rooms) {
      dispatch(
        setPreorderData({
          rooms: preorderData?.rooms || 2,
          bathrooms: preorderData?.bathrooms || 1,
        })
      );
    } else if (preorderData?.windows) {
      dispatch(
        setPreorderData({
          windows: preorderData?.windows || 2,
          balconies: preorderData?.balconies || 1,
        })
      );
    }
  }, [preorderData]);

  return (
    <ModalContainer width={500} centered={true} padding={'0'}>
      <S.Inner>
        <S.CloseButton onClick={closeModal}>
          <CloseIcon />
        </S.CloseButton>
        <LoginForm closeModal={closeModal} phone={phone} />
      </S.Inner>
    </ModalContainer>
  );
}

export default AuthModal;
