import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import {
  ADVANTAGE_ITEM_LIST,
  ALL_SERVICES_LIST,
} from '@/modules/Templates/Office/Office.constants';
import { colors } from '@/constants/theme';
import ContentContainer from '@/components/ContentContainer';
import { ReactComponent as CheckIcon } from '@/assets/svg/check.svg';

import { Section, SectionTitle } from '../Office.style';

function Advantages() {
  return (
    <Section>
      <ContentContainer>
        <SectionTitle>
          Getclean - сервис №1 уборки квартир в Беларуси
        </SectionTitle>
        <SectionContent>
          <Left>
            <List>
              {ADVANTAGE_ITEM_LIST.map((advantage, index) => (
                <Item key={index}>
                  <ItemTitle>{advantage.title}</ItemTitle>
                  {advantage.text}
                </Item>
              ))}
            </List>
          </Left>
          <Right>
            <RightTitle>Все сервисы GetClean</RightTitle>
            <ServicesList>
              {ALL_SERVICES_LIST.map((service, index) => (
                <ServicesItem key={index}>
                  <CheckIcon />
                  {service}
                </ServicesItem>
              ))}
            </ServicesList>
          </Right>
        </SectionContent>
      </ContentContainer>
    </Section>
  );
}

const SectionContent = styled.div`
  display: flex;

  ${media.tabletSmall(css`
    flex-wrap: wrap;
  `)}
`;

const Left = styled.div`
  flex: 1 1 auto;
  padding-right: 40px;

  ${media.tabletSmall(css`
    margin-bottom: 20px;
  `)}
`;

const List = styled.ul`
  font-size: 22px;
  line-height: 1.2;
  color: #2a2a2a;

  ${media.laptop(css`
    font-size: 20px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
  `)}
`;

const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 35px;
  }

  ${media.laptop(css`
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  `)}

  ${media.tablet(css`
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  `)}
  
  ${media.mobile(css`
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  `)}
`;
const ItemTitle = styled.span`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
`;

const Right = styled.div`
  flex: 0 0 410px;
  padding: 35px 50px 40px 20px;
  border-radius: 10px;
  background-color: ${colors.gray};
  overflow: hidden;

  ${media.laptop(css`
    padding: 30px 40px 30px 20px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
  `)}
  
  ${media.tabletSmall(css`
    flex: 1 1 auto;
    font-size: 16px;
    padding: 20px;
  `)}
`;

const RightTitle = styled.span`
  display: block;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  color: #2a2a2a;

  ${media.laptop(css`
    margin-bottom: 25px;
  `)}

  ${media.tabletSmall(css`
    margin-bottom: 20px;
  `)}
`;

const ServicesList = styled.ul`
  ${media.tabletSmall(css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `)}
`;

const ServicesItem = styled.li`
  position: relative;
  padding-left: 25px;
  font-size: 20px;
  line-height: 1.2;
  color: #2a2a2a;

  svg {
    position: absolute;
    top: 6px;
    left: 0;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  ${media.laptop(css`
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    font-size: 18px;
  `)}

  ${media.tablet(css`
    font-size: 16px;
  `)}
  
  ${media.tabletSmall(css`
    font-size: 14px;
    width: 50%;
    padding-right: 20px;
  `)}
  
  ${media.mobile(css`
    width: 100%;
    padding-right: 0;
  `)}
`;

export default Advantages;
