import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { windowAndBalconyInfoListSelector } from '@/store/reducers/prices';
import { colors } from '@/constants/theme';
import { getPrimaryOrderServiceBySystemName } from '@/utils/common';

type Props = {
  title: string;
  price?: number;
  windows?: number;
  balconies?: number;
};

function CostItem({ title, price, windows, balconies }: Props) {
  const windowAndBalconyInfoList = useSelector(
    windowAndBalconyInfoListSelector
  );

  function getTotalWindowPrice() {
    if (!windowAndBalconyInfoList) return 0;
    const windowServiceList =
      getPrimaryOrderServiceBySystemName(windowAndBalconyInfoList, 'WINDOWS')
        ?.services ?? [];

    return (
      windowServiceList.find(
        (windowService) => windowService.quantity === windows
      )?.price ?? 0
    );
  }

  function getTotalBalconyPrice() {
    if (!windowAndBalconyInfoList) return 0;
    const balconyServiceList =
      getPrimaryOrderServiceBySystemName(windowAndBalconyInfoList, 'BALCONIES')
        ?.services ?? [];

    return (
      balconyServiceList.find(
        (balconyService) => balconyService.quantity === balconies
      )?.price ?? 0
    );
  }

  return (
    <Item>
      <Title>{title}</Title>
      <Price>
        {windows
          ? `${getTotalWindowPrice()}`
          : balconies
          ? `${getTotalBalconyPrice()}`
          : null}
        {` `}
        {price ? price : null}
      </Price>
    </Item>
  );
}

const Item = styled.div`
  width: 100%;
  padding: 48px 10px;
  border-radius: 8px;
  background-color: ${colors.white};
  box-shadow: 0 2px 4px 0 #d2cfcf;
  transition: background-color 0.3s ease;
`;

const Title = styled.span`
  display: block;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  color: ${colors.black};
  text-align: center;
`;

const Price = styled.span`
  display: block;
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  color: ${colors.green};
  text-align: center;
`;

export default CostItem;
