import React from 'react';

import useSliderMedia from '@/hooks/useSliderMedia';
import ReviewItem from '@/modules/Templates/Career/components/ReviewItem';
import { DEFAULT_SLIDER_CONFIG } from '@/constants/common';
import image1Src from '@/assets/images/cleaners/cleaner4.jpg';
import image1Src2x from '@/assets/images/cleaners/cleaner4@2x.jpg';
import image1WebpSrc from '@/assets/images/cleaners/cleaner4.webp';
import image1WebpSrc2x from '@/assets/images/cleaners/cleaner4@2x.webp';
import image2Src from '@/assets/images/cleaners/cleaner3.jpg';
import image2Src2x from '@/assets/images/cleaners/cleaner3@2x.jpg';
import image2WebpSrc from '@/assets/images/cleaners/cleaner3.webp';
import image2WebpSrc2x from '@/assets/images/cleaners/cleaner3@2x.webp';
import image3Src from '@/assets/images/cleaners/cleaner5.jpg';
import image3Src2x from '@/assets/images/cleaners/cleaner5@2x.jpg';
import image3WebpSrc from '@/assets/images/cleaners/cleaner5.webp';
import image3WebpSrc2x from '@/assets/images/cleaners/cleaner5@2x.webp';

import CareerSection, { Item, List, SliderContainer } from './CareerSection';

const ITEM_1 = {
  imgUrl: image1Src,
  img2xUrl: image1Src2x,
  imgWebpUrl: image1WebpSrc,
  img2xWebpUrl: image1WebpSrc2x,
  name: 'Татьяна',
  text: '«Для меня важно, чтобы все было стабильно. Я заранее знаю, сколько уборок у меня на этой неделе, сколько я заработаю и когда будут выплаты».',
};

const ITEM_2 = {
  imgUrl: image2Src,
  img2xUrl: image2Src2x,
  imgWebpUrl: image2WebpSrc,
  img2xWebpUrl: image2WebpSrc2x,
  name: 'Наталья',
  text: '«Очень приятные коллектив и руководство — дружные и готовые всегда помочь, подсказать, выручить и поощрить».',
};

const ITEM_3 = {
  imgUrl: image3Src,
  img2xUrl: image3Src2x,
  imgWebpUrl: image3WebpSrc,
  img2xWebpUrl: image3WebpSrc2x,
  name: 'Анна',
  text: '«Мне нравится, что свой рабочий график я могу регулировать сама и, тем самым, влиять на свой доход».',
};

const ITEM_LIST = [ITEM_1, ITEM_2, ITEM_3];

function CleanersReview() {
  const ref = useSliderMedia('(max-width: 1023px)', DEFAULT_SLIDER_CONFIG);
  return (
    <CareerSection title="Отзывы клинеров">
      <SliderContainer className="swiper-container" ref={ref}>
        <List className="swiper-wrapper">
          {ITEM_LIST.map((item, index) => (
            <Item className="swiper-slide" key={index}>
              <ReviewItem item={item} />
            </Item>
          ))}
        </List>
        <div className="swiper-pagination" />
      </SliderContainer>
    </CareerSection>
  );
}

export default CleanersReview;
