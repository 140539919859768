import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureImage,
  ThumbnailType,
} from '@tager/web-modules';
import { useModal } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { SectionTitle } from '@/modules/Templates/Home/Home.style';
import ContentContainer from '@/components/ContentContainer';
import Button from '@/components/__old__/Button';
import Picture from '@/components/Picture';
import AboutCleaning from '@/components/__old__/modals/Home/AboutCleaning/AboutCleaningModal';
import { ModalWithScrollOverlay } from '@/components/__old__/Modal.style';

type Props = {
  image: {
    desktop: ThumbnailType;
    laptop: ThumbnailType;
    tablet: ThumbnailType;
  };
  title: string;
  content: string;
  buttonLabel?: string;
  withModal?: boolean;
};

function TabContent({ title, content, buttonLabel, image, withModal }: Props) {
  const openModal = useModal();

  function handleAboutCleaning() {
    openModal(
      AboutCleaning,
      {},
      {
        scrollLockDisabled: false,
        withAnimation: false,
        components: {
          Overlay: ModalWithScrollOverlay,
        },
      }
    );
  }

  return (
    <TabContentBlock>
      <TabContentBg>
        {image ? (
          <Picture
            desktop={convertThumbnailToPictureImage(image.desktop)}
            laptop={convertThumbnailToPictureImage(image.laptop)}
            tabletLarge={convertThumbnailToPictureImage(image.tablet)}
          />
        ) : null}
      </TabContentBg>
      <TabContentInner>
        <ContentContainer>
          <ContentInfo>
            <ContentTitle>{title}</ContentTitle>
            <ContentList
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
            {(withModal && buttonLabel) || buttonLabel ? (
              <PopupOpenButton variant={'green'} onClick={handleAboutCleaning}>
                {buttonLabel}
              </PopupOpenButton>
            ) : null}
          </ContentInfo>
        </ContentContainer>
      </TabContentInner>
    </TabContentBlock>
  );
}

const TabContentBlock = styled.div`
  position: relative;
  min-height: 600px;
  z-index: 0;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: -1;
  }

  ${media.tabletSmall(css`
    min-height: 0;
  `)}
`;

const fillCss = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const TabContentBg = styled.div`
  ${fillCss};
  z-index: -1;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  ${media.tabletSmall(css`
    display: none;
  `)}
`;

const TabContentInner = styled.div`
  padding: 75px 0 30px;

  ${media.tabletSmall(css`
    padding: 0;
  `)}
`;

const ContentInfo = styled.div`
  margin-left: 50%;
  padding-left: 80px;

  ${media.tabletSmall(css`
    padding-left: 0;
    max-width: 505px;
    margin: 0 auto;
    text-align: center;
  `)}
`;

const ContentTitle = styled(SectionTitle)`
  margin-bottom: 35px;
  text-align: left;
`;

const ContentList = styled.div`
  text-align: left;

  li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;

    &:before {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      width: 9px;
      height: 2px;
      background-color: #c2c2c2;
    }
  }
`;

const ListItem = styled.li``;

const PopupOpenButton = styled(Button)`
  margin-top: 45px;
  padding: 22px 45px;
`;

export default TabContent;
