import { ServerResponse } from 'http';

import Router from 'next/router';
import React from 'react';

import { PageShortType } from '@tager/web-modules';
import { Nullish } from '@tager/web-core';
import { SeoParamsType } from '@tager/web-modules/src/typings/models';
import { Page } from '@tager/web-components';

import { City } from '@/services/requests/misc';
import { PageModuleType } from '@/typings/common';
import { getPageModuleByTemplate } from '@/services/pageModules';

type PageData = {
  pageModule: PageModuleType;
  pageCity: City | null;
  baseUrl: string;
};

export function getPageDataByUrl(
  pageList: PageShortType[],
  currentPath: string,
  cities: City[]
): null | PageData {
  let foundPage = pageList.find((page) => page.path === currentPath);
  let foundCity = null;

  if (!foundPage) {
    const cityRegexp = /\/(.+?)\/(.+)$/;
    const cityRegexpData = cityRegexp.exec(currentPath);
    if (cityRegexpData) {
      const cityAlias = cityRegexpData[1];
      foundCity = cities
        .filter((item) => !item.isDefault)
        .find((item) => item.urlAlias === cityAlias);

      if (foundCity) {
        currentPath = '/' + cityRegexpData[2];
        foundPage = pageList
          .filter((page) => page.template !== 'default')
          .find((page) => page.path === currentPath);
      }
    }
  }

  if (!foundPage) {
    foundCity = cities
      .filter((item) => !item.isDefault)
      .find((item) => item.urlAlias === currentPath.substring(1));

    if (foundCity) {
      currentPath = '/';
      foundPage = pageList
        .filter((page) => page.template !== 'default')
        .find((page) => page.path === currentPath);
    }
  }

  if (!foundPage) {
    return null;
  }

  const foundPageModule = getPageModuleByTemplate(foundPage?.template);

  if (!foundPageModule) {
    return null;
  }

  if (foundPageModule.template === '' && foundCity && !foundCity.isDefault) {
    return null;
  }

  return {
    pageModule: foundPageModule,
    pageCity: foundCity || cities[0],
    baseUrl: currentPath,
  };
}

export function getPageBaseUrl(
  currentPath: string,
  cities: City[]
): string | null {
  const cityRegexp = /\/(.+?)\/(.+)$/;
  const cityRegexpData = cityRegexp.exec(currentPath);

  if (cityRegexpData) {
    const cityAlias = cityRegexpData[1];
    const foundCity = cities
      .filter((item) => !item.isDefault)
      .find((item) => item.urlAlias === cityAlias);

    if (foundCity) {
      currentPath = '/' + cityRegexpData[2];
    }
  } else {
    const foundCity = cities
      .filter((item) => !item.isDefault)
      .find((item) => item.urlAlias === currentPath.substring(1));

    if (foundCity) {
      currentPath = '/';
    }
  }

  return currentPath || null;
}

export async function redirect(
  redirectUrl: string,
  res: ServerResponse | undefined
) {
  if (res) {
    res.setHeader('Location', redirectUrl);
    res.writeHead(301).end();
  } else {
    await Router.replace(redirectUrl);
  }
  return {};
}

export function processText(text: string, city: City | null): string {
  if (!city) return text;

  return text
    .replaceAll('{{city_where}}', city.nameWhere)
    .replaceAll('{{city}}', city.name);
}

export function convertSeoParamsWithRegionToPageProps(
  seoData: Nullish<SeoParamsType>,
  city: City | null
): React.ComponentProps<typeof Page> {
  return {
    title: processText(seoData?.title || '', city),
    description: processText(seoData?.description || '', city),
    keywords: processText(seoData?.keywords || '', city),
    openGraphImage: processText(seoData?.openGraphImage || '', city),
  };
}
