import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import bgImgUrlDesktop from '@/assets/images/career/hero/vacancy-bg.jpg';
import bgImgUrlDesktop2x from '@/assets/images/career/hero/vacancy-bg@2x.jpg';
import bgImgUrlDesktopWebp from '@/assets/images/career/hero/vacancy-bg.webp';
import bgImgUrlDesktop2xWebp from '@/assets/images/career/hero/vacancy-bg@2x.webp';
import bgImgUrlLaptop from '@/assets/images/career/hero/vacancy-bg-laptop.jpg';
import bgImgUrlLaptop2x from '@/assets/images/career/hero/vacancy-bg-laptop@2x.jpg';
import bgImgUrlLaptopWebp from '@/assets/images/career/hero/vacancy-bg-laptop.webp';
import bgImgUrlLaptop2xWebp from '@/assets/images/career/hero/vacancy-bg-laptop@2x.webp';
import bgImgUrlTablet from '@/assets/images/career/hero/vacancy-bg-tablet.jpg';
import bgImgUrlTablet2x from '@/assets/images/career/hero/vacancy-bg-tablet@2x.jpg';
import bgImgUrlTabletWebp from '@/assets/images/career/hero/vacancy-bg-tablet.webp';
import bgImgUrlTablet2xWebp from '@/assets/images/career/hero/vacancy-bg-tablet@2x.webp';
import useSettingItem from '@/hooks/useSettingItem';
import { colors } from '@/constants/theme';

import HireForm from './HireForm';

function Hero() {
  const layoutWorkerMinSalary = useSettingItem('LAYOUT_WORKER_MIN_SALARY');

  return (
    <Section>
      <ContentWrapper>
        <Background>
          <Picture
            desktop={{
              src: bgImgUrlDesktop,
              src2x: bgImgUrlDesktop2x,
              webp: bgImgUrlDesktopWebp,
              webp2x: bgImgUrlDesktop2xWebp,
            }}
            laptop={{
              src: bgImgUrlLaptop,
              src2x: bgImgUrlLaptop2x,
              webp: bgImgUrlLaptopWebp,
              webp2x: bgImgUrlLaptop2xWebp,
            }}
            mobileSmall={{
              src: bgImgUrlTablet,
              src2x: bgImgUrlTablet2x,
              webp: bgImgUrlTabletWebp,
              webp2x: bgImgUrlTablet2xWebp,
            }}
          />
        </Background>
        <Salary>
          от <span>{layoutWorkerMinSalary} руб.</span> в меcяц
        </Salary>
        <ContentContainer>
          <Inner>
            <Content>
              <Title>Ищем специалистов по уборке в Минске</Title>
              <FormWrapper>
                <HireForm />
              </FormWrapper>
            </Content>
          </Inner>
        </ContentContainer>
      </ContentWrapper>
    </Section>
  );
}

const Section = styled.section`
  padding-top: 60px;
  overflow: hidden;

  ${media.tablet(css`
    padding-top: 70px;
  `)};

  ${media.tablet(css`
    padding-top: 66px;
  `)};
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 100px 0;

  ${media.tabletSmall(
    css`
      padding: 95px 0 20px;
    `
  )};
`;

const Salary = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px 0;
  background: ${colors.yellow};
  font-weight: 500;
  font-size: 15px;

  span {
    font-weight: bold;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;
  }
`;

const Inner = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const Content = styled.div`
  max-width: 780px;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  max-width: 480px;
  margin-bottom: 45px;
  margin-top: 0;

  ${media.mobile(css`
    margin-bottom: 30px;
    max-width: 240px;
    font-size: 28px;
  `)};
`;

const FormWrapper = styled.div`
  ${media.tabletSmallOnly(css`
    max-width: 460px;
  `)}
`;

export default Hero;
