import styled from 'styled-components';

import Button from '@/components/__old__/Button';
import { colors } from '@/constants/theme';

export const Inner = styled.div`
  padding: 40px;
  position: relative;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 15px;
  transition: 0.5s;
  svg {
    width: 12px;
    height: 12px;
    display: block;
    fill: ${colors.green};
  }
  &:hover {
    background-color: #dcdcdc6e;
  }
`;

export const Title = styled.span`
  display: block;
  font-weight: 500;
  font-size: 23px;
  line-height: 32px;
  margin: 0 0 25px;
  text-align: center;
`;

export const TextField = styled.p`
  display: block;
  text-align: center;
  margin-bottom: 25px;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: top;
`;

export const InputRow = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const SignUpButtonRow = styled.div`
  margin-bottom: 46px;
`;

export const SignUpButton = styled.button`
  text-decoration: underline;
  color: ${colors.green};
  &:hover {
    text-decoration: none;
  }
`;

export const SignInButtonRow = styled.div`
  margin-top: 20px;
`;

export const ForgotPasswordButtonRow = styled.div`
  text-align: center;
`;

export const SignInButton = styled(Button)`
  border: 0;
`;

export const SignUpText = styled.span`
  font-weight: 500;
`;

export const SignInText = styled.p`
  font-size: 14px;
  line-height: 17px;
`;

export const SignInTextContainer = styled.div`
  margin-top: 15px;
`;

export const SuccessTextContainer = styled.div`
  margin-bottom: 15px;
`;

export const SignInTextButton = styled.button`
  font-weight: 600;
`;

export const ForgotPasswordButton = styled.button`
  text-decoration: underline;
  color: ${colors.green};
  &:hover {
    text-decoration: none;
  }
`;

export const LoginBottom = styled.div`
  margin: 40px -40px -40px;
  padding: 15px 20px;
  background-color: #f5f5f5;
  text-align: center;
  border-radius: 0 0 5px 5px;
`;

export const FormErrorLabel = styled.span`
  position: absolute;
  top: 100%;
  margin-top: 2px;
  font-size: 12px;
  color: ${colors.red};
`;
