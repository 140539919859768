import { useRouter } from 'next/router';

import { PageFullType } from '@tager/web-modules';

import { selectPageByPath } from '@/store/reducers/tager/pages';
import { useTypedSelector } from '@/store/store';
import { convertSlugToPath } from '@/utils/common';
import { selectCities } from '@/store/reducers/cities';
import { getPageBaseUrl } from '@/utils/regions';

function useCurrentPage<P extends PageFullType>(): P {
  const router = useRouter();

  let currentPath = convertSlugToPath(router.query.slug);

  const cities = useTypedSelector(selectCities);
  const baseUrl = getPageBaseUrl(currentPath, cities) || '';
  const page = useTypedSelector((state) => selectPageByPath<P>(state, baseUrl));

  if (!page || baseUrl === '') {
    throw new Error('Page not found');
  }

  return page;
}

export default useCurrentPage;
