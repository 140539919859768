import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

export const ItemIcon = styled.div`
  display: inline-flex;
  align-items: flex-end;
  height: 65px;
  margin-bottom: 30px;
  color: ${colors.green};

  ${media.mobile(
    css`
      margin-bottom: 20px;
    `
  )};
`;

export const ItemTitle = styled.span`
  display: block;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;

  ${media.mobile(
    css`
      margin-bottom: 15px;
    `
  )};
`;
