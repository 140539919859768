import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

export type TabType = {
  name: string;
};

type Props = {
  className?: string;
  tab: TabType;
  isActive: boolean;
  onChange: (newTab: TabType) => void;
};

function Tab({ className, tab, isActive, onChange }: Props) {
  return (
    <TabItem className={className}>
      <TabButton isActive={isActive} onClick={() => onChange(tab)}>
        {tab.name}
      </TabButton>
    </TabItem>
  );
}

const TabItem = styled.li`
  display: flex;
  text-align: center;
  width: 25%;
  flex-grow: 1;

  &:first-child {
    border-radius: 5px 0 0 5px;
    overflow: hidden;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
    overflow: hidden;
  }

  ${media.tabletSmall(css`
    width: calc(50% - 5px);

    &:nth-child(n) {
      margin: 5px 2.5px;
    }
  `)}
`;

const TabButton = styled.button<{ isActive: boolean }>`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 15px 30px;
  ${(props) =>
    props.isActive
      ? css`
          color: ${colors.white};
          background-color: ${colors.green};
        `
      : css`
          color: #88919d;
          background-color: #f4f4f4;

          &:hover {
            background-color: #e7e7e7;
          }
        `};
`;

export default Tab;
