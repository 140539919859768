import GuaranteeIconUrl from '@/assets/svg/guarantee.svg';
import CleanerIconUrl from '@/assets/svg/cleaner.svg';
import CalendarIconUrl from '@/assets/svg/calendar.svg';

const ORDER_ITEM1 = {
  title: 'Выберите дату уборки',
  content: 'Вы заказываете уборку на любую удобную для себя дату.',
  image: { url: GuaranteeIconUrl },
};

const ORDER_ITEM2 = {
  title: 'Встретьте клинера GetClean',
  content:
    'К вам приезжает прошедший обучение и контроль службы безопасности клинер GetClean',
  image: { url: CleanerIconUrl },
};

const ORDER_ITEM3 = {
  title: 'Наслаждайтесь чистотой',
  content:
    'Наше качество уборки всегда на высоком уровне, но если оно вас не устроит - мы все переделаем бесплатно',
  image: { url: CalendarIconUrl },
};

export const HOWTO_ORDER_ITEMS = [ORDER_ITEM1, ORDER_ITEM2, ORDER_ITEM3];
