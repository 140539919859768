import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import {
  Section,
  SectionTitle,
} from '@/modules/Templates/Windows/Windows.style';
import ContentContainer from '@/components/ContentContainer';
import Tab from '@/components/__old__/Tab';
import TabContent from '@/components/__old__/TabContent';

type Props = {
  templateFields: Record<string, any>;
};

function IncludedInService({ templateFields }: Props) {
  const [activeTab, setActiveTab] = useState(templateFields?.servicesTabs[0]);

  const tabContent = templateFields.servicesTabs.find(
    (tabContent: { name: string }) =>
      activeTab ? tabContent.name === activeTab.name : null
  );

  return (
    <SectionTabs>
      <ContentContainer>
        <SectionTitle>{templateFields.servicesTitle}</SectionTitle>
      </ContentContainer>
      <SectionContent>
        <ContentContainer>
          <TabList>
            {templateFields.servicesTabs.map((tab: { name: string }) =>
              activeTab ? (
                <Tab
                  key={tab.name}
                  tab={tab}
                  isActive={tab.name === activeTab.name}
                  onChange={setActiveTab}
                />
              ) : null
            )}
          </TabList>
        </ContentContainer>
        {tabContent && <TabContent {...tabContent} />}
      </SectionContent>
    </SectionTabs>
  );
}

const SectionTabs = styled(Section)`
  padding-bottom: 0;

  ${media.tabletSmall(css`
    padding: 50px 0;
  `)}
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabList = styled.ul`
  display: flex;
  width: 400px;
  margin: 0 auto 60px;

  ${media.mobile(css`
    width: auto;
  `)}
`;

export default IncludedInService;
