import React from 'react';

import {
  Section,
  SectionTitle,
} from '@/modules/Templates/Windows/Windows.style';
import ContentContainer from '@/components/ContentContainer';

import StepList from './components/StepList';

type Props = {
  templateFields: Record<string, any>;
};

function HowItWorks({ templateFields }: Props) {
  const title = templateFields.howToTitle;
  const howToItems = templateFields.howToItems;
  return (
    <Section isOverflow={true}>
      <ContentContainer>
        {title ? <SectionTitle>{title}</SectionTitle> : null}
        {howToItems ? <StepList howToItems={howToItems} /> : null}
      </ContentContainer>
    </Section>
  );
}

export default HowItWorks;
