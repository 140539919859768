import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  FormikErrors,
  FormikValues,
  Form,
  FormikHelpers,
  FormikProps,
  Formik,
} from 'formik';
import { TFunction } from 'react-i18next';

import { convertRequestErrorToMap } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { requiredMessage, validators } from '@/utils/validation';
import { CareerRequestBody, requestCareer } from '@/services/requests/requests';
import MaskedTextInputFormik from '@/components/__old__/MaskedTextInput/MaskedTextInput.formik';
import { TextInputFormik } from '@/components/__old__/TextInput';
import { colors } from '@/constants/theme';
import Button from '@/components/__old__/Button';
import { useTranslation } from '@/i18n';
import useSettingItem from '@/hooks/useSettingItem';
import { getPhoneNumber } from '@/utils/common';

export type FormValues = { name: string; age: string; phone: string };

function validate(values: FormValues, t: TFunction<string>) {
  const errors: FormikErrors<FormikValues> = {};

  if (validators.required(values.phone)) {
    errors.phone = t(requiredMessage);
  }

  if (validators.required(values.age.toString())) {
    errors.age = t(requiredMessage);
  } else if (Number(values.age) < 18) {
    errors.age = 'error_young';
  } else if (Number(values.age) > 80) {
    errors.age = 'error_old';
  }

  if (validators.required(values.name)) {
    errors.name = t(requiredMessage);
  }

  return errors;
}

type Props = {
  successMessage?: string;
};

function HireFormContainer() {
  const [successMessage, setSuccessMessage] = useState('');
  const { t } = useTranslation();

  function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) {
    const body: CareerRequestBody = {
      name: values.name,
      age: Number(values.age),
      phone: values.phone,
    };
    requestCareer(body)
      .then((response) => {
        if (response.success) {
          setSuccessMessage('Заявка успешно отправлена');
          helpers.setSubmitting(false);
          helpers.resetForm();
        } else {
          throw new Error('Произошла ошибка');
        }
      })
      .catch((error) => {
        setSuccessMessage('');
        helpers.setErrors(convertRequestErrorToMap(error));
        helpers.setSubmitting(false);
      });
  }

  return (
    <Formik<FormValues>
      initialValues={{
        phone: '',
        name: '',
        age: '',
      }}
      onSubmit={handleSubmit}
      validate={(values) => validate(values, t)}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formProps) => (
        <HireForm {...formProps} successMessage={successMessage} />
      )}
    </Formik>
  );
}

function HireForm({
  isSubmitting,
  successMessage,
}: FormikProps<FormValues> & Props) {
  const phone = useSettingItem('LAYOUT_CAREER_PHONE');

  return (
    <Form>
      <Inner>
        <FieldSet>
          <InputContainer>
            <InputLabel>ФИО</InputLabel>
            <TextInputFormik
              id="full-name"
              name="name"
              type="text"
              clearErrorOnChange
              disabled={isSubmitting}
              data-testid="full-name-field"
            />
          </InputContainer>
          <InputContainer>
            <InputLabel>Телефон для связи</InputLabel>
            <MaskedTextInputFormik
              prefix={'+375'}
              type="tel"
              name="phone"
              clearErrorOnChange
              mask="(00) 000-00-00"
              data-testid="phone-field"
              placeholder="(__) ___-__-__"
              disabled={isSubmitting}
            />
          </InputContainer>
          <InputContainerNarrow>
            <InputLabel>Возраст</InputLabel>
            <MaskedTextInputFormik
              type="number"
              name="age"
              clearErrorOnChange
              mask="00"
              data-testid="age-field"
              disabled={isSubmitting}
            />
          </InputContainerNarrow>
        </FieldSet>
        <SubmitButton
          type="submit"
          disabled={isSubmitting}
          loader={isSubmitting}
        >
          Отправить
        </SubmitButton>
        <Text>
          Телефон для связи по вакансии{' '}
          {phone ? (
            <PhoneLink href={`tel:+${getPhoneNumber(phone)}`}>
              {phone}
            </PhoneLink>
          ) : null}
        </Text>
        {successMessage ? (
          <SuccessMessage>{successMessage}</SuccessMessage>
        ) : null}
      </Inner>
    </Form>
  );
}

const SuccessMessage = styled.span`
  position: absolute;
  top: 100%;
  font-size: 20px;
  line-height: 20px;
  display: block;
  margin-top: 10px;
  color: ${colors.green};
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  color: ${colors.blackText};
`;

const FieldSet = styled.ul`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  ${media.tabletSmall(css`
    flex-wrap: wrap;
  `)};
`;

const inputContainerCss = css`
  width: calc(33.3333% - 27px);
  &:not(:last-child) {
    margin-right: 40px;
  }

  ${media.tabletSmall(css`
    width: 100%;
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  `)};
`;

const InputContainer = styled.li`
  ${inputContainerCss};
`;

const InputContainerNarrow = styled.li`
  ${inputContainerCss};

  ${media.tabletLargeOnly(css`
    width: 20%;
  `)};
`;

const InputLabel = styled.label`
  display: inline-flex;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const SubmitButton = styled(Button)`
  background-color: ${colors.green};
  color: ${(props) => (props.loader ? 'transparent' : `${colors.white}`)};
  padding: 22px 40px;

  ${media.mobile(css`
    width: 100%;
  `)};

  &:hover {
    background-color: ${colors.greenHover};
  }
`;

const Text = styled.p`
  margin: 15px 0 0;
  font-size: 16px;
`;

const PhoneLink = styled.a`
  transition: 0.3s ease;
  color: ${colors.green};

  &:hover {
    color: ${colors.greenHover};
    text-decoration: underline;
  }
`;

export default HireFormContainer;
