import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';

type Props = {
  itemLink: {
    label: string;
    link: string;
  };
};

export function AboutCleaningItemLink({
  itemLink,
  closeModal,
}: Props & { closeModal?: () => void }) {
  return (
    <Item onClick={closeModal}>
      <Link as={ItemLink} to={itemLink?.link}>
        {itemLink?.label}
      </Link>
    </Item>
  );
}

function ItemLinkList({
  closeModal,
  servicesExtendedLinks,
}: {
  closeModal?: () => void;
  servicesExtendedLinks: Array<{ label: string; link: string }>;
}) {
  return (
    <List>
      {servicesExtendedLinks.map((itemLink, index) => (
        <AboutCleaningItemLink
          closeModal={closeModal}
          key={index}
          itemLink={itemLink}
        />
      ))}
    </List>
  );
}

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  ${media.mobile(css`
    margin: 0;
  `)}
`;

const Item = styled.li`
  display: inline-flex;
  padding: 5px 0;
  width: calc(50% - 30px);
  margin: 0 15px;

  ${media.mobile(css`
    width: 100%;
    margin: 0;
  `)}
`;

const ItemLink = styled.a`
  font-size: 16px;
  line-height: 19px;
  color: ${colors.green};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  ${media.mobile(css`
    font-size: 14px;
    line-height: 17px;
  `)}
`;

export default ItemLinkList;
