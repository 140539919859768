import React from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';
import { useModal } from '@tager/web-components';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import CostItem from '@/components/__old__/CostItem';
import Form from '@/components/__old__/modals/Form';

import { Section, SectionTitle } from './SeoComponents.style';

type Props = {
  title: Nullable<string>;
  subtitle?: Nullable<string>;
  costItems: Array<{ title: string; price: number }>;
  textBottom?: string;
};

function CostSection({ title, textBottom, subtitle, costItems }: Props) {
  return title || subtitle || (costItems && costItems.length) || textBottom ? (
    <SectionGray>
      <ContentContainer>
        {title ? <SectionTitle>{title}</SectionTitle> : null}
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        {costItems && costItems.length ? (
          <List>
            {costItems.map((item, index) => (
              <Item key={index}>
                <CostItem title={item.title} price={item.price} />
              </Item>
            ))}
          </List>
        ) : null}
        {textBottom ? (
          <SectionBottom
            dangerouslySetInnerHTML={{
              __html: textBottom,
            }}
          ></SectionBottom>
        ) : null}
      </ContentContainer>
    </SectionGray>
  ) : null;
}

const SectionGray = styled(Section)`
  background-color: ${colors.grayBg};
`;

const Subtitle = styled.span`
  display: block;
  max-width: 500px;
  margin: 0 auto 50px;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: -0.6px;
  text-align: center;

  ${media.mobile(css`
    font-size: 18px;
    line-height: 28px;
  `)}
`;

const List = styled.ul`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  ${media.tabletSmall(css`
    flex-wrap: wrap;
    max-width: 280px;
    margin: 0 auto 40px;
  `)}
`;

const Item = styled.li`
  width: calc(33.333% - 26.3px);
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 40px;
  }

  ${media.tabletSmall(css`
    width: 100%;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  `)}
`;

const SectionBottom = styled.div`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${colors.darkText};

  a {
    color: ${colors.green};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default CostSection;
