import React from 'react';
import styled, { css } from 'styled-components';

import { ModalProps } from '@tager/web-components';

import {
  ModalCloseButton,
  ModalContainer,
} from '@/components/__old__/Modal.style';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Button from '@/components/__old__/Button';
import { ReactComponent as CloseIcon } from '@/assets/svg/close-popup.svg';
import { useRepairData } from '@/modules/Templates/Repair/Repair.hooks';
import Picture from '@/components/Picture';

type Props = ModalProps;

function CleaningList({ closeModal }: Props) {
  const {
    whatIsIncludedDetailedListTitle,
    whatIsIncludedDetailedList,
    whatIsIncludedDetailedQuestions,
    whatIsIncludedDetailedButtonTitle,
  } = useRepairData();

  function handleClose() {
    closeModal();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <ModalContainer>
      <ModalCloseButton onClick={closeModal} variant={'yellow'}>
        <CloseIcon />
      </ModalCloseButton>

      <CleaningListContainer>
        <MainTitle>{whatIsIncludedDetailedListTitle}</MainTitle>
        <InnerList>
          {whatIsIncludedDetailedList?.map(({ image, title, list }, index) => (
            <InnerItem key={index}>
              <ItemIcon src={image.url} />
              <ItemTitle>{title}</ItemTitle>
              <SubList>
                {list?.split('\n').map((item, index) => (
                  <SubItem key={index}>{item}</SubItem>
                ))}
              </SubList>
            </InnerItem>
          ))}
        </InnerList>
      </CleaningListContainer>

      <BottomBlock>
        <BottomBlockText
          dangerouslySetInnerHTML={{
            __html: whatIsIncludedDetailedQuestions ?? '',
          }}
        />
        <OrderButtonWrapper>
          <OrderButton onClick={handleClose}>
            {whatIsIncludedDetailedButtonTitle}
          </OrderButton>
        </OrderButtonWrapper>
      </BottomBlock>
    </ModalContainer>
  );
}

const CleaningListContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0 30px;
  border-radius: 5px;
  background-color: ${colors.white};
  overflow: hidden;

  ${media.mobile(css`
    padding: 0 15px;
  `)}
`;

const MainTitle = styled.span`
  display: block;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 20px;

  ${media.mobile(css`
    font-size: 24px;
    line-height: 30px;
  `)}
`;

const InnerList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const InnerItem = styled.li`
  width: 50%;
  padding: 15px;

  ${media.mobile(css`
    width: 100%;
  `)}
`;

const ItemIcon = styled(Picture)`
  display: inline-flex;
  align-items: flex-end;
  height: 65px;
  margin-bottom: 30px;
`;

const ItemTitle = styled.span`
  display: block;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
`;

const SubList = styled.ul``;

const SubItem = styled.li`
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;

  &:before {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    width: 5px;
    height: 5px;
    background-color: ${colors.blackText};
    border-radius: 100%;
  }

  ${media.mobile(css`
    font-size: 14px;
  `)}
`;

const BottomBlock = styled.div`
  padding: 20px 30px;
`;

const BottomBlockText = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: #565656;
  max-width: 400px;
  margin-bottom: 20px;

  a {
    color: ${colors.yellowDark};
  }

  ${media.mobile(css`
    font-size: 16px;
  `)}
`;

const OrderButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const OrderButton = styled(Button)`
  display: inline-flex;
  font-size: 16px;
  line-height: 1;
  padding: 20px 45px;
  max-width: 220px;
  background-color: ${colors.yellowDark};

  ${media.mobile(css`
    font-size: 15px;
  `)}

  &:hover {
    background-color: ${colors.yellow};
  }
`;

export default CleaningList;
