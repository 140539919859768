import React from 'react';

import Layout from '@/components/Layout';

import Hero from './components/Hero';
import Advantages from './components/Advantages';
import CleanersReview from './components/CleanersReview';

function Career() {
  return (
    <Layout config={{ header: { colorVariant: 'green', isMainBg: false } }}>
      <Hero />
      <Advantages />
      <CleanersReview />
    </Layout>
  );
}

export default Career;
