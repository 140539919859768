import React, { useState } from 'react';

import { authPhoneCode, updateUserDevice } from '@/services/requests/requests';
import TextInput from '@/components/TextInput/TextInput';
import { getBrowserName } from '@/utils/browser';

import * as S from '../AuthModal.style';

type Props = {
  session: string;
  initialCode?: string;
  setLoading: (value: boolean) => void;
  onSubmit: (code: string, token: string | null) => void;
};

function LoginFormCodeView({
  session,
  setLoading,
  onSubmit,
  initialCode = '',
}: Props) {
  const [code, setCode] = useState<string>(initialCode);

  const onSubmitClick = async () => {
    setLoading(true);
    try {
      const response = await authPhoneCode(session, code);
      onSubmit(code, response.data.token);
      await updateUserDevice({
        device: getBrowserName(),
      });
      if (!response.data.token) {
        setLoading(false);
      }
    } catch (e) {
      alert('Неверный код');
      setLoading(false);
    }
  };

  return (
    <>
      <S.InputRow>
        <TextInput
          label="Код из SMS:"
          placeholder="1234"
          maxLength={4}
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
      </S.InputRow>
      <S.SignInButtonRow>
        <S.SignInButton
          variant="green"
          type="submit"
          fullWidth={true}
          disabled={code.length < 4}
          onClick={onSubmitClick}
        >
          Войти
        </S.SignInButton>
      </S.SignInButtonRow>
    </>
  );
}

export default LoginFormCodeView;
