import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';

type LinkComponent = {
  label: string;
  link: string;
};

type Props = {
  className?: string;
  serviceItem: {
    title: string;
    text: string;
    linkComponents: Array<LinkComponent>;
  };
};

function ServicesItem({ className, serviceItem }: Props) {
  return (
    <Item className={className}>
      <ItemTitle>{serviceItem.title}</ItemTitle>
      <ItemInfo>{serviceItem.text}</ItemInfo>
      <ItemList>
        {serviceItem.linkComponents.map((item, index) => (
          <Link as={ItemContentLink} to={item.link} key={index}>
            {item.label}
          </Link>
        ))}
      </ItemList>
    </Item>
  );
}

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px 34px 34px;
  border-radius: 10px;
  background-color: ${colors.gray};
  height: 100%;
  width: 100%;
  overflow: hidden;

  ${media.mobile(css`
    padding: 20px;
  `)}
`;

const ItemTitle = styled.span`
  display: block;
  margin: 0 -10px 15px;
  font-size: 17px;
  line-height: 100%;
  font-weight: 700;
  color: ${colors.blueDark};
  text-align: center;

  ${media.mobile(css`
    margin-bottom: 10px;
    font-size: 15px;
  `)}
`;

const ItemInfo = styled.p`
  margin-bottom: 25px;
  font-size: 15px;
  line-height: 17px;
  color: #868686;
  text-align: center;

  ${media.mobile(css`
    margin-bottom: 15px;
    font-size: 14px;
  `)}
`;

const ItemList = styled.div`
  margin-top: auto;
  background-color: ${colors.white};
  border-radius: 5px;
  box-shadow: -0.262px 4.993px 18.4px 1.6px rgba(0, 0, 0, 0.1);
`;

const ItemContentLink = styled.a<{ isActive: boolean }>`
  position: relative;
  display: block;
  padding: 22px;
  font-size: 15px;
  line-height: 17px;
  color: ${colors.blueDark};
  background-color: ${colors.white};

  &:not(:last-child) {
    border-bottom: 1px solid rgb(197, 206, 183);
  }

  ${media.mobile(css`
    padding: 20px;
    font-size: 15px;
    cursor: default;
  `)}

  ${(props) =>
    !props.isActive
      ? css`
          ${media.laptopUp(css`
            &:hover {
              background-color: ${colors.yellowLight};
            }
          `)}
        `
      : css``}
`;

export default ServicesItem;
