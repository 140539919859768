import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { TYPE_OF_CLEANING_LIST } from '@/modules/Templates/Office/Office.constants';
import { colors } from '@/constants/theme';
import ContentContainer from '@/components/ContentContainer';

import { Section, SectionTitle, TypeTitle } from '../Office.style';

type ItemProps = {
  title: {
    type: string;
    label: string;
  };
  description?: string;
};

function TypeOfCleaningItem({ title, description }: ItemProps) {
  const { type, label } = title;

  function getTitle() {
    switch (type) {
      case 'blue':
        return <ItemTitleBlue>{label}</ItemTitleBlue>;
      case 'dark':
        return <ItemTitleDark>{label}</ItemTitleDark>;
      case 'yellow':
        return <ItemTitleYellow>{label}</ItemTitleYellow>;
      default:
        return <ItemTitleBlue>{label}</ItemTitleBlue>;
    }
  }

  return (
    <ListItem>
      <TypeTitle>{getTitle()}</TypeTitle>
      {description ? <Description>{description}</Description> : null}
    </ListItem>
  );
}

function TypesOfCleaning() {
  return (
    <Section>
      <ContentContainer>
        <SectionTitle>Виды уборок офиса</SectionTitle>
        <List>
          {TYPE_OF_CLEANING_LIST.map((item, index) => (
            <TypeOfCleaningItem
              key={index}
              title={item.title}
              description={item.description}
            />
          ))}
        </List>
      </ContentContainer>
    </Section>
  );
}

const List = styled.ul``;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 75px;
  }

  ${media.laptop(css`
    &:not(:last-child) {
      margin-bottom: 55px;
    }
  `)}

  ${media.tablet(css`
    &:not(:last-child) {
      margin-bottom: 45px;
    }
  `)}
  
  ${media.mobile(css`
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  `)}
`;

const itemTitleCss = css`
  padding: 6px 10px;
  box-decoration-break: clone;

  ${media.tablet(css`
    padding: 6px;
  `)}

  ${media.mobile(css`
    padding: 5px;
  `)}
`;

const ItemTitleBlue = styled.span`
  ${itemTitleCss};
  background-color: ${colors.blue};
`;

const ItemTitleDark = styled.span`
  ${itemTitleCss};
  background-color: #282828;
`;

const ItemTitleYellow = styled.span`
  ${itemTitleCss};
  color: #282828;
  background-color: ${colors.yellow};
`;

const Description = styled.p`
  margin: 0;
  font-size: 25px;
  line-height: 1.2;
  color: rgb(42, 42, 42);

  ${media.laptop(css`
    font-size: 22px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
  `)}
  
  ${media.mobile(css`
    font-size: 18px;
  `)}
`;

export default TypesOfCleaning;
