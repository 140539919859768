import React from 'react';
import styled from 'styled-components';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { ReactComponent as PhotoFrame } from '@/assets/svg/photo-frame.svg';

type Props = {
  item: {
    imgUrl: string;
    img2xUrl: string;
    imgWebpUrl: string;
    img2xWebpUrl: string;
    name: string;
    text: string;
  };
};

function ReviewItem({ item }: Props) {
  const { imgUrl, img2xUrl, imgWebpUrl, img2xWebpUrl, name, text } = item;
  return (
    <ItemContainer>
      <Photo>
        <PhotoFrame />
        <Picture
          src={imgUrl}
          src2x={img2xUrl}
          srcWebp={imgWebpUrl}
          srcWebp2x={img2xWebpUrl}
        />
      </Photo>
      <Title>
        <Name>{name}</Name>
        <SubTitle>Клинер</SubTitle>
      </Title>
      <Text>{text}</Text>
    </ItemContainer>
  );
}

const ItemContainer = styled.div`
  text-align: center;
  padding-top: 10px;
`;

const Photo = styled.div`
  position: relative;
  width: 160px;
  height: 160px;
  margin: 0 auto 40px;

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 145px;
    max-height: 145px;
    z-index: 2;
    display: block;
    border-radius: 100%;
    object-fit: cover;
  }

  svg {
    position: absolute;
    overflow: visible;
    left: -8px;
    bottom: 5px;
  }
`;

const Title = styled.div`
  margin-bottom: 20px;
`;

const Name = styled.span`
  display: block;
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
`;

const SubTitle = styled.span`
  display: block;
  font-size: 16px;
  line-height: 20px;
  color: #88919d;
`;

const Text = styled.p`
  line-height: 26px;
  padding: 13px;
  border-radius: 5px;
  background-color: ${colors.grayBg};
`;

export default ReviewItem;
