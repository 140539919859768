import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import useSliderMedia from '@/hooks/useSliderMedia';
import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import Button from '@/components/__old__/Button';
import { colors } from '@/constants/theme';
import CleaningList from '@/components/__old__/modals/CleaningList/CleaningList';
import { DEFAULT_SLIDER_CONFIG } from '@/constants/common';
import { ModalWithScrollOverlay } from '@/components/__old__/Modal.style';
import { useRepairData } from '@/modules/Templates/Repair/Repair.hooks';

import { SectionTitle } from '../Repair.style';

import CleaningItem from './CleaningItem';

function WhatIsIncluded() {
  const { whatIsIncludedTitle, whatIsIncludedList, whatIsIncludedButtonTitle } =
    useRepairData();
  const ref = useSliderMedia('(max-width: 1260px)', DEFAULT_SLIDER_CONFIG);
  const openModal = useModal();

  if (
    !whatIsIncludedTitle ||
    !whatIsIncludedList ||
    !whatIsIncludedButtonTitle
  ) {
    return null;
  }

  function handleCleaningList() {
    openModal(
      CleaningList,
      {},
      {
        withAnimation: false,
        scrollLockDisabled: false,
        components: {
          Overlay: ModalWithScrollOverlay,
        },
      }
    );
  }

  return (
    <Section>
      <ContentContainer>
        <Title>{whatIsIncludedTitle}</Title>
        <div className="swiper-container" ref={ref}>
          <List className="swiper-wrapper">
            {whatIsIncludedList?.map(({ image, title, text }, index) => (
              <Item
                key={index}
                image={image}
                title={title}
                text={text}
                className="swiper-slide"
              />
            ))}
          </List>
          <SliderPagination className="swiper-pagination" />
        </div>
        <SectionBottom>
          <ListButton onClick={handleCleaningList}>
            {whatIsIncludedButtonTitle}
          </ListButton>
        </SectionBottom>
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  padding: 100px 0 70px;
  background-color: #ebedf4;
  overflow: hidden;

  ${media.tablet(css`
    padding: 80px 0;
  `)}

  ${media.mobile(css`
    padding: 60px 0;
  `)}
`;

const Title = styled(SectionTitle)`
  text-align: center;
  margin-bottom: 50px;

  ${media.tablet(css`
    margin-bottom: 40px;
  `)}

  ${media.mobile(css`
    margin-bottom: 30px;
  `)}
`;

const List = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Item = styled(CleaningItem)`
  &:not(:last-child) {
    margin-right: 30px;
  }
`;

const SliderPagination = styled.div<{ isHidden?: boolean }>`
  position: relative;
  margin: 30px auto 0;
  bottom: 0 !important;
  left: auto !important;
  width: auto !important;

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;

    &-active {
      background-color: ${colors.yellowDark};
    }
  }

  ${media.laptopUp(css`
    display: none;
  `)}
`;

const SectionBottom = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  ${media.mobile(css`
    margin-top: 35px;
  `)}
`;

const ListButton = styled(Button)`
  font-size: 18px;
  padding: 20px 60px;
  background-color: ${colors.yellowDark};

  &:hover {
    background-color: ${colors.yellow};
  }
`;

export default WhatIsIncluded;
