import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { Form, Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import { TFunction } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Nullable } from '@tager/web-core';
import { googleAnalytics, gtag } from '@tager/web-analytics';
import { useModal } from '@tager/web-components';

import AuthModal from '@/modules/Modals/AuthModal';
import { validators } from '@/utils/validation';
import { colors } from '@/constants/theme';
import Button from '@/components/__old__/Button';
import { media } from '@/utils/mixin';
import { MaskedTextInputFormik } from '@/components/__old__/MaskedTextInput';
import { useTranslation } from '@/i18n';
import useSettingItem from '@/hooks/useSettingItem';
import { roomAndBathroomInfoListSelector } from '@/store/reducers/prices';
import { getPrimaryOrderServiceBySystemName } from '@/utils/common';
import { useTypedSelector } from '@/store/store';
import { selectIsUserAuthorized } from '@/store/reducers/auth';
import { analyticsOrderStartTriggerEvent } from '@/utils/analytics';
import { setOrderStartRequest } from '@/services/requests/orders';
import { selectActiveCity } from '@/store/reducers/cities';

import CounterFormik from './Counter';

export type FormValues = {
  phone: string;
  code: string;
  rooms?: number;
  bathrooms?: number;
  windows?: number;
  balconies?: number;
};

function validate(values: FormValues, t: TFunction<string>) {
  const errors: FormikErrors<FormikValues> = {};

  if (validators.required(values.phone)) {
    errors.phone = t('empty_phone');
  }

  return errors;
}

type Props = {
  submitLabel?: React.ReactNode;
};

function CalculatorFormContainer(props: Props) {
  const { t } = useTranslation();
  const phonePrefix = useSettingItem('LAYOUT_PHONE_PREFIX');

  return (
    <Formik<FormValues>
      initialValues={{
        code: '',
        phone: '',
        rooms: 1,
        bathrooms: 1,
        windows: 0,
        balconies: 0,
      }}
      onSubmit={() => {}}
      validate={(values) => validate(values, t)}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formProps) => (
        <CalculatorForm {...formProps} {...props} phonePrefix={phonePrefix} />
      )}
    </Formik>
  );
}

function CalculatorForm({
  submitLabel,
  values,
}: FormikProps<FormValues> & Props & { phonePrefix: Nullable<string> }) {
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const city = useTypedSelector(selectActiveCity);

  const isUserAuthorized = useTypedSelector((state) =>
    selectIsUserAuthorized(state)
  );

  const openModal = useModal();
  const router = useRouter();

  const { t } = useTranslation();

  /** Get Room and Bathroom Order Info */

  const roomAndBathroomInfoList = useSelector(roomAndBathroomInfoListSelector);

  const roomInfoList = getPrimaryOrderServiceBySystemName(
    roomAndBathroomInfoList,
    'ROOMS'
  );

  const bathroomInfoList = getPrimaryOrderServiceBySystemName(
    roomAndBathroomInfoList,
    'BATHROOMS'
  );

  const onSubmit = async (e: any) => {
    setPhoneError(null);

    if (!isUserAuthorized) {
      if (validators.required(values.phone)) {
        setPhoneError('Введите номер телефона');
        return;
      }
      if (validators.phoneWithCode('+375' + values.phone)) {
        setPhoneError('Неверный номер телефона');
        return;
      }
    }

    analyticsOrderStartTriggerEvent();

    setOrderStartRequest({
      phone: values.phone,
      windows: values.windows || 2,
      balconies: values.balconies || 1,
      city: city ? city.id : null,
    }).then();

    if (isUserAuthorized) {
      const searchParams = new URLSearchParams();
      searchParams.set('rooms', String(values.rooms));
      searchParams.set('bathrooms', String(values.bathrooms));
      googleAnalytics.trackEvent('event', 'standart_price');

      router.push({ pathname: '/order', search: searchParams.toString() });
    } else {
      openModal(AuthModal, {
        phone: '+375' + values.phone,
        preorderData: {
          rooms: values.rooms || 2,
          bathrooms: values.bathrooms || 1,
        },
      });
    }
  };

  return (
    <FormContainer data-testid="cost-request-form">
      <FormContainerInner>
        <CounterRow>
          {roomInfoList && roomInfoList.services.length ? (
            <CounterFormik
              name="rooms"
              getLabel={(count) => {
                if (count) {
                  return roomInfoList.services.find(
                    (service) => service.quantity === count
                  )?.name;
                }
                return roomInfoList.emptyValueLabel;
              }}
              max={roomInfoList?.services.length}
              min={1}
            />
          ) : null}
        </CounterRow>
        <CounterRow>
          {bathroomInfoList && bathroomInfoList.services.length ? (
            <CounterFormik
              name="bathrooms"
              getLabel={(count) => {
                if (count) {
                  return bathroomInfoList.services.find(
                    (service) => service.quantity === count
                  )?.name;
                }
                return bathroomInfoList.emptyValueLabel;
              }}
              max={bathroomInfoList?.services.length}
              min={1}
            />
          ) : null}
        </CounterRow>
        {!isUserAuthorized ? (
          <InputContainer>
            <MaskedTextInputFormik
              prefix={'+375'}
              type="tel"
              name="phone"
              clearErrorOnChange
              definitions={{ X: /[0-9]/, Х: /[0-9]/, '0': /0/ }}
              mask={'(XX) XXX-XX-XX'}
              placeholder={'Номер телефона'}
              error={phoneError || ''}
            />
          </InputContainer>
        ) : null}
        <FormButton type="submit" variant={'yellow'} onClick={onSubmit}>
          {submitLabel ?? t('header_form_label')}
        </FormButton>
      </FormContainerInner>
    </FormContainer>
  );
}

const FormContainer = styled.div``;

const FormContainerInner = styled(Form)`
  color: ${colors.blackText};
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  ${media.tabletSmall(css``)}
  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const CounterRow = styled.div`
  flex: 1 1 1px;
  ${media.tablet(css`
    margin-bottom: 20px;
  `)}
  &:last-child {
    margin-bottom: 0;
  }
`;

const FormButton = styled(Button)`
  line-height: 20px;
  padding: 15px 50px;
  white-space: nowrap;
  color: ${colors.black};
  background-color: ${colors.yellowLight};
  font-weight: 700;
  ${media.tablet(css`
    background-color: ${colors.green};
    color: ${colors.white};
    &:hover {
      background-color: ${colors.greenHover};
    }
  `)}
`;

const InputContainer = styled.div`
  position: relative;
  width: 260px;
  min-width: 260px;
  input {
    height: 57px;
    font-size: 16px;
  }
  ${media.tablet(css`
    margin-bottom: 20px;
    width: 100%;
    min-width: 100%;
  `)}
`;

export default CalculatorFormContainer;
