import { useEffect, useRef } from 'react';
import Swiper from 'swiper';

import { useMedia } from '@tager/web-core';

function useSliderMedia(media: string, config?: object) {
  const sliderRef = useRef<HTMLDivElement>(null);
  const isMediaBreakpoint = useMedia(media);
  useEffect(() => {
    if (!sliderRef.current) return;
    if (!isMediaBreakpoint) return;
    const defaultConfig = {
      spaceBetween: 20,
      speed: 500,
    };
    const sliderConfig = config ?? defaultConfig;
    const swiper = new Swiper(sliderRef.current, sliderConfig);

    return () => swiper.destroy(true, true);
  }, [config, isMediaBreakpoint]);

  return sliderRef;
}

export default useSliderMedia;
