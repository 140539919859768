import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

export const SectionTitle = styled.h2`
  margin-bottom: 35px;
  font-size: 32px;
  line-height: 1.2;
  font-weight: bold;
  color: ${colors.darkText};

  ${media.mobile(css`
    font-size: 26px;
  `)}
`;
