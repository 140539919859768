import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { useRepairData } from '@/modules/Templates/Repair/Repair.hooks';

import { SectionTitle } from '../Repair.style';

function HowItWorks() {
  const { howItWorksTitle, howItWorksText, howItWorksList } = useRepairData();

  if (!howItWorksTitle || !howItWorksText || !howItWorksList) {
    return null;
  }

  const titleItems = howItWorksTitle.split('\n');

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    if ((event.target as HTMLDivElement).tagName.toLowerCase() === 'strong') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  return (
    <Section>
      <ContentContainer>
        <SectionInner>
          <Left>
            {howItWorksList?.map(({ text }, index) => (
              <LeftItem key={index}>{text}</LeftItem>
            ))}
          </Left>
          <Right>
            <SectionTitle>
              {titleItems?.map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  {titleItems.length - 1 !== index && <br />}
                </React.Fragment>
              ))}
            </SectionTitle>
            <RightText
              onClick={(e) => handleClick(e)}
              dangerouslySetInnerHTML={{ __html: howItWorksText ?? '' }}
            />
          </Right>
        </SectionInner>
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  padding: 120px 0 140px;
  color: ${colors.darkText};
  background-color: #fcfcfd;

  ${media.tablet(css`
    padding: 80px 0;
  `)}

  ${media.mobile(css`
    padding: 60px 0;
  `)}
`;

const SectionInner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tabletSmall(css`
    flex-wrap: wrap;
    justify-content: stretch;
  `)}
`;

const Left = styled.ul`
  counter-reset: section;
  width: 50%;

  ${media.tabletSmall(css`
    width: 100%;
  `)}
`;

const LeftItem = styled.li`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 70px;
  padding-left: 100px;
  font-size: 24px;
  line-height: 1.2;

  &:before {
    counter-increment: section;
    content: counters(section, '.');
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 70px;
    height: 70px;
    font-size: 34px;
    line-height: 58px;
    text-align: center;
    color: ${colors.darkText};
    font-weight: 600;
    border: 6px solid ${colors.yellowDark};
    border-radius: 50%;
  }

  &:not(:last-child) {
    margin-bottom: 35px;
  }

  ${media.tabletSmall(css`
    padding-left: 80px;
    font-size: 22px;

    &:before {
      width: 60px;
      height: 60px;
      font-size: 30px;
      line-height: 50px;
      border-width: 5px;
    }

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  `)}

  ${media.mobileMedium(css`
    padding-top: 5px;
  `)}
`;

const Right = styled.div`
  width: 40%;

  ${media.tabletSmall(css`
    width: 100%;
    margin-bottom: 30px;
    order: -1;
  `)}
`;

const RightText = styled.div`
  font-size: 20px;
  line-height: 1.2;
  width: 90%;

  p:not(:last-child) {
    margin-bottom: 25px;
  }

  p {
    strong {
      cursor: pointer;
      font-weight: normal;
      color: ${colors.yellowDark};

      &:hover {
        opacity: 0.8;
      }
    }
  }

  ${media.tabletSmall(css`
    width: 100%;
  `)}
`;

export default HowItWorks;
