import styled, { css } from 'styled-components';

import { CssSnippet, ModalOverlay } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

export const ModalContainer = styled.div<{
  width?: number;
  centered?: boolean;
  padding?: string;
}>`
  ${(props) =>
    props.centered
      ? css`
          position: absolute;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);
        `
      : css`
          position: relative;
          margin: 0 auto;
        `};

  max-width: ${(props) => props.width || 780}px;
  width: 100vw;
  border-radius: 5px;
  background-color: ${colors.white};
  padding: ${(props) => props.padding || '40px 0 20px'};
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);

  ${media.tabletSmall(css`
    max-width: 95vw;
  `)}
`;

export type ReservedButtonColorVariant = 'yellow' | 'green';

export type ButtonColorVariant = ReservedButtonColorVariant | CssSnippet;

const variantCssMap: Record<ReservedButtonColorVariant, CssSnippet> = {
  yellow: css`
    color: ${colors.yellowDark};

    &:hover {
      ${colors.yellowLight}
    }
  `,
  green: css`
    color: ${colors.green};

    &:hover {
      ${colors.greenHover}
    }
  `,
};

export const ModalCloseButton = styled.button<{ variant?: ButtonColorVariant }>`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  transition: 0.3s ease;
  border-radius: 0 5px 0 0;

  svg {
    width: 12px;
    height: 12px;
  }

  &:hover {
    background-color: #fafafa;
  }

  ${(props) =>
    typeof props.variant === 'string'
      ? variantCssMap[props.variant]
      : props.variant};
`;

export const ModalWithScrollOverlay = styled(ModalOverlay)`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: auto;
  padding: 50px 0;
  display: block !important;
  z-index: 3000 !important;

  & > div {
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
  }
`;
