import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

type Props = {
  templateFields: Record<string, any>;
};

function Hero({ templateFields }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <HeroContainer ref={containerRef}>
      <Background>
        <MobileBg>
          <Shadow />
          <Picture {...convertThumbnailToPictureProps(templateFields.image)} />
        </MobileBg>
      </Background>
      <ContentContainer>
        <HeroInner>
          <Intro>
            <Title>
              {templateFields.title}
              <TitleLineColored>{templateFields.titleBold}</TitleLineColored>
            </Title>

            <Cleaner>
              <Picture
                {...convertThumbnailToPictureProps(templateFields.image)}
              />
            </Cleaner>
          </Intro>
        </HeroInner>
      </ContentContainer>
    </HeroContainer>
  );
}

const HeroContainer = styled.div`
  position: relative;
  padding: 100px 20px 70px;
  width: 100%;
  z-index: 0;
  display: flex;
  align-items: center;

  ${media.tablet(css`
    min-height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
    align-items: normal;
  `)}

  ${media.mobile(css`
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 55px;
  `)};
`;

const Shadow = styled.div`
  width: 100%;
  height: 100%;
  background-color: #0000005c;
  position: absolute;
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    position: absolute;
  }
`;

const MobileBg = styled.div`
  display: none;

  ${media.tablet(css`
    display: block;
  `)}
`;

const ContentContainer = styled.div`
  width: 100%;
  margin-top: 90px;
  margin-bottom: 60px;

  ${media.tablet(css`
    margin-top: 30px;
    margin-bottom: 0;
  `)}

  ${media.tabletSmall(css`
    padding: 0 20px;
    max-width: 767px;
    margin: 0 auto;
  `)}
  
  ${media.mobileMedium(css`
    padding: 0 10px;
  `)};
`;

const HeroInner = styled.div`
  position: relative;
`;
const Cleaner = styled.div`
  position: absolute;
  top: -140px;
  z-index: -1;
  width: 100%;
  height: 100%;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    z-index: -1;
    object-fit: cover;
    min-width: 1160px;
    border-radius: 45px;
  }

  ${media.tablet(css`
    display: none;

    img {
      border-radius: 0;
    }
  `)}
`;

const Intro = styled.div`
  position: relative;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 20px;

  ${media.tablet(css`
    padding: 0;
    text-align: center;
  `)}
`;

const Title = styled.h1`
  margin: 50px 0 0 80px;
  font-size: 96px;
  font-weight: 900;
  line-height: 95px;
  color: ${colors.white};
  letter-spacing: -3px;

  ${media.tablet(css`
    color: ${colors.white};
    margin: 70px 0 0 0;
  `)}

  ${media.mobile(css`
    font-size: 39px;
    text-transform: uppercase;
    line-height: 60px;
    margin-bottom: 15px;
    letter-spacing: 0;
  `)}
`;

const TitleLineColored = styled.span`
  display: block;

  ${media.laptopUp(css`
    color: ${colors.white};
  `)}
`;

export default Hero;
