import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import Link from '@/components/Link';

import { ItemIcon, ItemTitle } from '../AboutCleaning.style';

type Props = {
  extraItem: {
    title: string;
    content: string;
    link: string;
    icon: { url: string };
  };
};

export function ExtraServiceItem({
  extraItem,
  closeModal,
}: Props & { closeModal?: () => void }) {
  const { icon, title, content, link } = extraItem;

  return (
    <Item>
      {link ? (
        <Link as={ItemLink} to={link}>
          <ItemContent onClick={closeModal}>
            <ItemIcon>
              <Picture {...convertThumbnailToPictureProps(icon)} />
            </ItemIcon>
            <ItemTitle>{title}</ItemTitle>
          </ItemContent>
        </Link>
      ) : (
        <>
          <ItemIcon>
            <Picture {...convertThumbnailToPictureProps(icon)} />
          </ItemIcon>
          <ItemTitle>{title}</ItemTitle>
        </>
      )}
      <ItemDescription>{content}</ItemDescription>
    </Item>
  );
}

function ExtraServiceList({
  closeModal,
  servicesExtendedSecondaryItems,
}: {
  closeModal?: () => void;
  servicesExtendedSecondaryItems: Array<{
    title: string;
    content: string;
    link: string;
    icon: { url: string };
  }>;
}) {
  return (
    <List>
      {servicesExtendedSecondaryItems.map((item, index) => (
        <ExtraServiceItem
          closeModal={closeModal}
          key={index}
          extraItem={item}
        />
      ))}
    </List>
  );
}

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -45px -15px;

  ${media.mobile(css`
    margin: -20px 0;
  `)}
`;

const Item = styled.li`
  width: calc(33.333% - 30px);
  margin: 45px 15px;

  ${media.mobile(css`
    width: 100%;
    margin: 20px 0;
    text-align: center;
  `)}
`;

const ItemLink = styled.a`
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    opacity: 0.7;
  }
`;

const ItemContent = styled.div`
  display: inline-block;
`;

const ItemDescription = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #868f9b;

  ${media.mobile(css`
    font-size: 14px;
  `)}
`;

export default ExtraServiceList;
