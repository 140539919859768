import React from 'react';
import styled from 'styled-components';

import Layout from '@/components/Layout';
import useCurrentPage from '@/hooks/useCurrentPage';
import ContactForm from '@/modules/Templates/Subscription/components/ContactForm';
import { submitFormSubscription } from '@/services/requests/requests';
import { OrderButton } from '@/modules/Templates/Subscription/components/OrderButton';

import Packages from './components/Packages';
import UnderHero from './components/UnderHero';
import Ordering from './components/Ordering';
import Pluses from './components/Pluses';
import Hero from './components/HeroBlock';
import { ContactFormData } from './components/ContactForm/ContactForm.types';

function Subscription() {
  const page = useCurrentPage();
  const templateFields = page.templateFields;

  if (!templateFields) return null;

  const saveFormSubscription = async (
    values: ContactFormData
  ): Promise<void> => {
    await submitFormSubscription(values);
  };

  return (
    <Layout
      config={{
        header: {
          colorVariant: 'green',
          isMainBg: true,
          isApartment: true,
        },
      }}
    >
      <Component>
        <Hero templateFields={templateFields} />
        <OrderButton />
        <UnderHero />
        {templateFields.plusesSubscriptionItems && <Pluses />}
        {templateFields.packageCostTabs && <Packages />}
        <Ordering />
      </Component>
    </Layout>
  );
}

export default Subscription;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
