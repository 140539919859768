import React from 'react';
import styled from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { Section, SectionTitle } from '@/modules/Templates/Home/Home.style';

import AccordsBlock from './components/AccordsBlock';

type Props = {
  faqTitle: Record<string, any>;
  faqItems: Array<{ answer: string; question: string }>;
};

function QaSection({ faqTitle, faqItems }: Props) {
  return faqTitle || (faqItems && faqItems.length) ? (
    <SectionGray>
      <ContentContainer>
        <SectionTitle>{faqTitle}</SectionTitle>
        <AccordsBlock faqItems={faqItems} />
      </ContentContainer>
    </SectionGray>
  ) : null;
}

const SectionGray = styled(Section)`
  background-color: ${colors.grayBg};
`;

export default QaSection;
