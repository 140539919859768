import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

export type Props = {
  closeModal: () => void;
  servicesExtendedCtaTitle: string;
  servicesExtendedCtaText: string;
  servicesExtendedCtaButtonLabel: string;
};

function OrderCleaningBlock({
  closeModal,
  servicesExtendedCtaTitle,
  servicesExtendedCtaText,
  servicesExtendedCtaButtonLabel,
}: Props) {
  function handleClose() {
    closeModal();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <Wrapper>
      {servicesExtendedCtaTitle ? (
        <BlockTitle
          dangerouslySetInnerHTML={{ __html: servicesExtendedCtaTitle }}
        />
      ) : null}
      {servicesExtendedCtaText ? (
        <BlockText
          dangerouslySetInnerHTML={{ __html: servicesExtendedCtaText }}
        />
      ) : null}
      {servicesExtendedCtaButtonLabel ? (
        <OrderButton onClick={handleClose}>
          {servicesExtendedCtaButtonLabel}
        </OrderButton>
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0 15px;

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const BlockTitle = styled.div`
  margin-bottom: 40px;
  font-size: 48px;
  line-height: 51px;
  font-weight: 900;

  span {
    color: ${colors.green};
  }

  ${media.mobile(css`
    font-size: 40px;
    margin-bottom: 30px;
  `)}
`;

const BlockText = styled.p`
  font-size: 18px;
  line-height: 28px;
  color: #565656;
  max-width: 400px;
  margin-bottom: 40px;
  span {
    display: inline-flex;
    margin-left: 5px;
    color: ${colors.green};
  }
  ${media.mobile(css`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  `)}
`;

const OrderButton = styled.button`
  display: inline-flex;
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  padding: 22px 45px;
  max-width: 220px;
  border-radius: 5px;
  color: ${colors.black};
  background-color: ${colors.yellowLight};

  &:hover {
    background-color: ${colors.yellow};
  }

  ${media.mobile(css`
    padding: 22px 35px;
  `)}
`;

export default OrderCleaningBlock;
