import { ConstantMap } from '@tager/web-core';

import { PageTemplateType } from '@/typings/model';

export const DEFAULT_SLIDER_CONFIG = {
  slidesPerView: 'auto',
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
};
