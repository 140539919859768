import React from 'react';

import Companies from '@/components/__old__/Companies';

type Props = {
  companyList: Array<{ image: { url: string }; url: string }>;
};

function CompaniesReviews({ companyList }: Props) {
  return <Companies companyList={companyList} />;
}

export default CompaniesReviews;
